import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedContent } from 'src/app/core/models/shared.model';

@Pipe({
  name: 'localizedContentText',
  pure: false,
})
export class LocalizedContentTextPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: LocalizedContent[], field: string): string {
    if (value == null || value.length === 0 || !field) {
      return '';
    }

    const currentLanguage = this.translateService.currentLang || this.translateService.defaultLang;
    let localized = value.find(v => v.language.codISO === currentLanguage);

    // Default lang
    if (localized == null) {
      localized = value.find(v => v.language.codISO === 'it');
    }

    if (localized == null) {
      return '';
    }

    return (localized as any)[field] || '';
  }
}
