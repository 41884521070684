export class EventData {
  name: string;
  value?: any;

  constructor(name: string, value?: any) {
    this.name = name;
    this.value = value;
  }
}

export enum EventDataEnum {
  Logout = 'logout',
  TokenRefreshed = 'token-refreshed',
  Notification = 'notification',
  ReloadHome = 'reload-home',
  ReloadOperations = 'reload-operations',
}
