<app-toolbar-header [toolbarType]="isDismissable ? 'modal' : 'main'" (toolbarClick)="closeModal()"></app-toolbar-header>

<div class="title-header">
  <ion-label class="Title c-dark-1">
    {{ (isDismissable ? 'menu.cambia_contesto' : 'login.select_role_message') | translate : { user: firstName } }}
  </ion-label>
  <ion-label class="Section-Title c-gray-1">
    {{ 'login.select_role_company' | translate }}
  </ion-label>
</div>
<ion-content>
  <ion-list lines="none">
    <ion-item *ngFor="let client of clients">
      <app-card-contesto
        [clientInfo]="client"
        (onClick)="selezionaContesto(client)"
        [class.active]="client.clientId === clientSelezionato"></app-card-contesto>
    </ion-item>
  </ion-list>
</ion-content>
