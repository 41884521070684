import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputStandardComponent } from './input-standard/input-standard.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputDatetimeComponent } from './input-datetime/input-datetime.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { InputToggleComponent } from './input-toggle/input-toggle.component';

@NgModule({
  declarations: [
    InputPasswordComponent,
    InputStandardComponent,
    InputDatetimeComponent,
    InputSelectComponent,
    InputTextareaComponent,
    InputToggleComponent,
  ],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [
    InputPasswordComponent,
    InputStandardComponent,
    InputDatetimeComponent,
    InputSelectComponent,
    InputTextareaComponent,
    InputToggleComponent,
  ],
})
export class CustomInputsModule {}
