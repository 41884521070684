export enum FrontendUserRolesEnum {
  SUPERADMIN = 'superadmin',
  OP_ADMIN = 'op_admin',
  OP_VALIDATOR = 'op_validator',
  OP_EMPLOYEE = 'op_employee',
  AZ_ADMIN = 'az_admin',
  AZ_VALIDATOR = 'az_validator',
  AZ_EMPLOYEE = 'az_employee',
  AZ_WITH_OP_ADMIN = 'az_with_op_admin',
  AZ_WITH_OP_VALIDATOR = 'az_with_op_validator',
  AZ_WITH_OP_EMPLOYEE = 'az_with_op_employee',
  ANALYST = 'analyst',
}

export enum UserRolesEnum {
  ADMIN = 'admin',
  CLIENT_ADMIN = 'clientAdmin',
  VALIDATORE = 'validatore',
  ADDETTO_INSERIMENTO = 'addetto',
}

export const userRoleValues = Object.values(UserRolesEnum);
