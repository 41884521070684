import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OperationAction } from '../models/operations.interface';
import { PaginatedList } from '../models/paginated.interface';
import {
  AntagonistOrganisms,
  CompanyInfo,
  CropAnalysis,
  CropOperation,
  FertOperation,
  FertType,
  Harvest,
  MeasurementUnity,
  OperationType,
  PhytosanitaryTreatment,
  ProcessingState,
  ProcessingStateCodeEnum,
} from '../models/supply-chain.interface';

@Injectable({
  providedIn: 'root',
})
export class SupplyChainService {
  public static COMPANY_EXISTS_ERROR = 'Company Exists';

  constructor(private http: HttpClient) {}

  public makeOperationActions(
    isValidator: boolean,
    operation?: CropOperation | FertOperation | PhytosanitaryTreatment | AntagonistOrganisms | CropAnalysis | Harvest
  ): Observable<OperationAction> {
    if (operation == null) {
      return of({
        edit: false,
        sendToValidate: false,
        validate: false,
        openCorrection: false,
        remove: false,
      });
    }
    return this.getProcessingStateMap().pipe(
      map(processingStateMap => {
        const row: OperationAction = {
          edit: false,
          sendToValidate: false,
          validate: false,
          openCorrection: false,
          remove: false,
        };
        switch (operation.processingState?.id) {
          case processingStateMap.get(ProcessingStateCodeEnum.INSERITO):
            row.edit = true;
            row.validate = isValidator === true;
            row.remove = true;
            row.sendToValidate = isValidator !== true;
            break;
          case processingStateMap.get(ProcessingStateCodeEnum.DA_VALIDARE):
            row.validate = isValidator === true;
            row.openCorrection = isValidator === true;
            row.remove = isValidator === true;
            break;
        }
        return row;
      })
    );
  }

  /**
   * Recupera le info sull'azienda
   *
   * @param vatNumber
   * @returns
   */
  public getCompanyInfoById(id: number): Observable<CompanyInfo> {
    return this.http.get(`${environment.gravitee}/api-supply-chain/company-info/${id}`).pipe(
      map(response => {
        return response as CompanyInfo;
      })
    );
  }

  /**
   * Recupera gli stati di processo, con caching
   *
   * @returns
   */
  public getProcessingState(): Observable<ProcessingState[]> {
    return this.http
      .get<PaginatedList<ProcessingState>>(`${environment.gravitee}/api-supply-chain/processing-states`, {
        context: withCache(),
      })
      .pipe(
        map(response => {
          const listStates = response.content || [];
          return listStates;
        })
      );
  }

  public getProcessingStateMap(): Observable<Map<ProcessingStateCodeEnum, number>> {
    return this.getProcessingState().pipe(
      map(listStates => {
        const processingStateMap = new Map();
        listStates.forEach(state => {
          processingStateMap.set(state.code, state.id);
        });
        return processingStateMap;
      })
    );
  }

  /**
   * Recupera le unità di misura
   *
   * @returns
   */
  public getMeasurementUnities(): Observable<MeasurementUnity[]> {
    return this.http
      .get<PaginatedList<MeasurementUnity>>(`${environment.gravitee}/api-supply-chain/measurement-unity`, {
        context: withCache(),
      })
      .pipe(
        map(response => {
          return response.content || [];
        })
      );
  }

  /**
   * Recupera le operation type
   *
   * @returns
   */
  public getOperationTypes(): Observable<OperationType[]> {
    return this.http
      .get<PaginatedList<OperationType>>(`${environment.gravitee}/api-supply-chain/operation-type`, {
        context: withCache(),
      })
      .pipe(
        map(response => {
          return response.content || [];
        })
      );
  }

  /**
   * Recupera le fert type
   *
   * @returns
   */
  public getFertTypes(): Observable<FertType[]> {
    return this.http
      .get<PaginatedList<FertType>>(`${environment.gravitee}/api-supply-chain/fert-type`, {
        context: withCache(),
      })
      .pipe(
        map(response => {
          return response.content || [];
        })
      );
  }

  /**
   * Recupera le info sull'azienda
   *
   * @param vatNumber
   * @returns
   */
  public getCompanyInfoByVatNumber(vatNumber: string): Observable<CompanyInfo | undefined> {
    return this.http.get(`${environment.gravitee}/api-supply-chain/company-info/findAllByVatNumber/${vatNumber}`).pipe(
      map(response => {
        const list = response as PaginatedList<CompanyInfo>;
        if (list && list.numberOfElements > 0) {
          return list.content[0];
        }
        return undefined;
      })
    );
  }
}
