import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaginatedList } from '../models/paginated.interface';
import { CompanyInfo } from '../models/supply-chain.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  /**
   * Recupera le info sull'azienda
   *
   * @param vatNumber
   * @returns
   */
  public getCompanyInfoByVatNumber(vatNumber: string): Observable<CompanyInfo | undefined> {
    return this.http.get(`${environment.gravitee}/api-supply-chain/company-info/findAllByVatNumber/${vatNumber}`).pipe(
      map(response => {
        const list = response as PaginatedList<CompanyInfo>;
        if (list && list.numberOfElements > 0) {
          return list.content[0];
        }
        return undefined;
      })
    );
  }
}
