import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController, NavController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, Observable, delay, map, switchMap, tap, zip } from 'rxjs';
import { CropCycleActionsComponent } from 'src/app/authenticated/components/crop-cycle-actions/crop-cycle-actions.component';
import { ActionItem } from 'src/app/authenticated/models/action-item.model';
import { LazyCropCycleService } from 'src/app/authenticated/services/lazy-crop-cycle.service';
import { CheckDeactivateForm } from 'src/app/core/guards/deactivate-form';
import { ProductionActionEnum } from 'src/app/core/models/action';
import { EventData, EventDataEnum } from 'src/app/core/models/event.class';
import { OperationAction } from 'src/app/core/models/operations.interface';
import { CompanyInfo, CropCycle, Harvest, ProcessingStateCodeEnum } from 'src/app/core/models/supply-chain.interface';
import { EventBusService } from 'src/app/core/services/event-bus.service';
import { HarvestLotService } from 'src/app/core/services/harvest-lot.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SessionService } from 'src/app/core/services/sessions.service';
import { SupplyChainService } from 'src/app/core/services/supply-chain.service';

interface IForm {
  id: FormControl<number | undefined>;
  harvestDate: FormControl<Date>;
  notes: FormControl<string>;
}

@Component({
  templateUrl: './add-edit-harvest-lots.component.html',
  styleUrls: ['./add-edit-harvest-lots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditHarvestLotsComponent extends CheckDeactivateForm implements OnDestroy {
  actionItems: ActionItem[] = [];
  harvestLot?: Harvest;
  currentCompany?: CompanyInfo;
  isEdit = false;
  disableActions$ = new BehaviorSubject<boolean>(false);
  cropCycle$ = new BehaviorSubject<CropCycle | undefined>(undefined);

  public harvestForm?: FormGroup<IForm>;

  public fieldFilterName: string;
  public operationActions$ = new BehaviorSubject<OperationAction | undefined>(undefined);
  private isValidator = this.sessionService.hasValidatorPermissions;

  constructor(
    navCtrl: NavController,
    private popoverController: PopoverController,
    private router: Router,
    private supplyChainService: SupplyChainService,
    private lazyService: LazyCropCycleService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private harvestLotService: HarvestLotService,
    private notification: NotificationService,
    private sessionService: SessionService,
    private eventBusService: EventBusService,
    private alertCtrl: AlertController,
    private cd: ChangeDetectorRef
  ) {
    super(navCtrl);
    const state = this.router.getCurrentNavigation()?.extras.state;
    if (state && state['harvest']) {
      this.isEdit = true;
      this.harvestLot = state['harvest'];
    }
    this.loadInitialData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async onConfirmClick(ev: { type: 'left' | 'right'; event: any }): Promise<void> {
    if (!this.isEdit) {
      this.insert();
      return;
    }
    if (this.actionItems == null || this.actionItems.length <= 0) {
      return;
    }
    const popover = await this.popoverController.create({
      component: CropCycleActionsComponent,
      event: ev.event,
      componentProps: {
        actionItems: this.actionItems,
      },
      backdropDismiss: true,
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (data?.actionType == null) {
      return;
    }

    switch (data.actionType as ProductionActionEnum) {
      case ProductionActionEnum.UPDATE:
        this.update();
        break;
      case ProductionActionEnum.OPEN_CORRECTION:
        await this.askConfirmAction(ProductionActionEnum.OPEN_CORRECTION);
        break;
      case ProductionActionEnum.SEND_TO_VALIDATE:
        await this.askConfirmAction(ProductionActionEnum.SEND_TO_VALIDATE);
        break;
      case ProductionActionEnum.VALIDATE:
        await this.askConfirmAction(ProductionActionEnum.VALIDATE);
        break;
    }
  }

  public async removeHarvest(): Promise<void> {
    const mode = Capacitor.getPlatform() === 'ios' ? 'ios' : 'md';

    const alert = await this.alertCtrl.create({
      header: this.translate.instant('commons.warning'),
      message: this.translate.instant('harvests.delete_ask'),
      mode,
      buttons: [
        {
          role: 'cancel',
          text: this.translate.instant('commons.no'),
        },
        {
          text: this.translate.instant('commons.yes'),
          handler: (): void => {
            this.doRemoveHarvest();
          },
        },
      ],
    });

    await alert.present();
  }

  public doRemoveHarvest(): void {
    if (this.harvestLot == null) {
      return;
    }

    this.loaderService.showLoader();

    this.harvestLotService
      .removeHarvest(this.harvestLot)
      .pipe(
        delay(1500) // Necessario altrimenti ricarica anche la crop operation eliminata
      )
      .subscribe({
        next: () => {
          this.eventBusService.emit(new EventData(EventDataEnum.ReloadOperations));
          this.notification.success('harvests.deleted_success');
          this.loaderService.hideLoader();
          this.navigateBack();
        },
        error: error => {
          if (error) {
            this.navigateBack();
          }
          this.loaderService.hideLoader();
        },
      });
  }

  /**
   * Operazione di inserimento nuovo HarvestLot
   */
  private insert(): void {
    const harvestLot = this.makeHarvestLot();
    if (harvestLot == null) {
      return;
    }

    this.loaderService.showLoader();

    this.harvestLotService.createHarvest(harvestLot).subscribe({
      next: () => {
        this.eventBusService.emit(new EventData(EventDataEnum.ReloadOperations));
        this.notification.success('harvests.created_success');
        this.loaderService.hideLoader();
        this.navigateBack();
      },
      error: err => {
        if (err) {
          this.notification.error('commons.error');
        }
        this.loaderService.hideLoader();
      },
    });
  }

  /**
   * Operazione di aggiornamento di una HarvestLot
   *
   * @returns
   */
  private update(): void {
    const harvestLot = this.makeHarvestLot();
    if (harvestLot == null || harvestLot.id == null) {
      return;
    }

    this.loaderService.showLoader();

    this.harvestLotService.updateHarvest(harvestLot).subscribe({
      next: () => {
        this.eventBusService.emit(new EventData(EventDataEnum.ReloadOperations));
        this.notification.success('harvests.update_success');
        this.loaderService.hideLoader();
        this.navigateBack();
      },
      error: err => {
        if (err) {
          this.notification.error('commons.error');
        }
        this.loaderService.hideLoader();
      },
    });
  }

  private async askConfirmAction(
    action: ProductionActionEnum.OPEN_CORRECTION | ProductionActionEnum.SEND_TO_VALIDATE | ProductionActionEnum.VALIDATE
  ): Promise<void> {
    const mode = Capacitor.getPlatform() === 'ios' ? 'ios' : 'md';

    const alert = await this.alertCtrl.create({
      header: this.translate.instant('commons.warning'),
      message: this.translate.instant(`harvests.ask_${action}`),
      mode,
      buttons: [
        {
          role: 'cancel',
          text: this.translate.instant('commons.no'),
        },
        {
          text: this.translate.instant('commons.yes'),
          handler: (): void => {
            switch (action) {
              case ProductionActionEnum.OPEN_CORRECTION:
                this.doOpenCorrection();
                break;
              case ProductionActionEnum.SEND_TO_VALIDATE:
                this.doSendToValidate();
                break;
              case ProductionActionEnum.VALIDATE:
                this.doValidate();
                break;
            }
          },
        },
      ],
    });

    await alert.present();
  }

  /**
   * Operazione di salvataggio e apertura correzione
   *
   * @returns
   */
  private doOpenCorrection(): void {
    const harvestLot = this.makeHarvestLot();
    if (harvestLot == null) {
      return;
    }
    this.loaderService.showLoader();

    // Aggiorno l'operazione prima
    this.harvestLotService
      .openCorrectionHarvest(harvestLot)
      .pipe(
        switchMap(hl => {
          // Risetto l'harvest lot e il form
          this.harvestLot = hl;
          this.harvestForm.reset();

          this.harvestForm.patchValue({
            id: this.harvestLot?.id,
            harvestDate: moment(this.harvestLot.harvestDate).startOf('day').toDate(),
            notes: this.harvestLot.notes,
          });

          return this.supplyChainService.makeOperationActions(this.isValidator, this.harvestLot);
        }),
        tap(operationActions => {
          this.operationActions$.next(operationActions);
          this.loadItemActions();
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.eventBusService.emit(new EventData(EventDataEnum.ReloadOperations));
          this.notification.success('harvests.success_open_correction');
          this.loaderService.hideLoader();
        },
        error: err => {
          if (err) {
            this.notification.error('commons.error');
          }
          this.loaderService.hideLoader();
        },
      });
  }

  /**
   * Operazione di salvataggio e invio in stato SEND_TO_VALIDATE
   *
   * @returns
   */
  private doSendToValidate(): void {
    const harvestLot = this.makeHarvestLot();
    if (harvestLot == null) {
      return;
    }
    this.loaderService.showLoader();

    // Aggiorno l'operazione prima
    this.harvestLotService
      .updateHarvest(harvestLot)
      .pipe(switchMap(hl => this.harvestLotService.sendToValidationHarvest(hl)))
      .subscribe({
        next: () => {
          this.eventBusService.emit(new EventData(EventDataEnum.ReloadOperations));
          this.notification.success('harvests.success_send_to_validate');
          this.loaderService.hideLoader();
          this.navigateBack();
        },
        error: err => {
          if (err) {
            this.notification.error('commons.error');
          }
          this.loaderService.hideLoader();
        },
      });
  }

  /**
   * Operazione di salvataggio e invio in stato VALIDATE(Blockchain OP campo e analisi precedenti)
   *
   * @returns
   */
  private doValidate(): void {
    const harvestLot = this.makeHarvestLot();
    if (harvestLot == null) {
      return;
    }
    this.loaderService.showLoader();

    if (this.harvestLot?.processingState?.code === ProcessingStateCodeEnum.DA_VALIDARE) {
      this.harvestLotService.sendHarvestInBlockChain(harvestLot).subscribe({
        next: () => {
          this.handleValidationSuccess();
        },
        error: err => {
          this.handleValidationError(err);
        },
      });
    } else {
      // Aggiorno l'operazione prima
      this.harvestLotService
        .updateHarvest(harvestLot)
        .pipe(switchMap(hl => this.harvestLotService.sendHarvestInBlockChain(hl)))
        .subscribe({
          next: () => {
            this.handleValidationSuccess();
          },
          error: err => {
            this.handleValidationError(err);
          },
        });
    }
  }

  private loadInitialData(): void {
    const currentCropCycle = this.lazyService.cropCycle$.getValue();
    if (currentCropCycle == null) {
      this.navigateBack();
      return;
    }
    this.loaderService.showLoader();
    this.loadTypes()
      .pipe(
        tap(() => {
          this.initForm(currentCropCycle);
          this.loadItemActions();
        })
      )
      .subscribe({
        next: () => {
          this.loaderService.hideLoader();
        },
        error: () => {
          this.loaderService.hideLoader();
        },
      });
  }

  /**
   * Load Types
   */
  private loadTypes(): Observable<void> {
    const currenClient = this.sessionService.currentClient;
    return zip(
      this.supplyChainService.makeOperationActions(this.isValidator, this.harvestLot),
      this.supplyChainService.getCompanyInfoByVatNumber(currenClient)
    ).pipe(
      map(res => {
        const [operationAction, company] = res;
        this.operationActions$.next(operationAction);
        this.currentCompany = company;
      })
    );
  }

  private loadItemActions(): void {
    if (!this.isEdit) {
      return;
    }

    const actions = this.operationActions$.getValue();

    const result: ActionItem[] = [];

    if (actions.edit) {
      result.push({
        label: 'commons.save',
        icon: 'fas fa-save',
        actionType: ProductionActionEnum.UPDATE,
      });
    }

    if (actions.openCorrection) {
      result.push({
        label: 'commons.open_correction',
        icon: 'fas fa-redo-alt',
        actionType: ProductionActionEnum.OPEN_CORRECTION,
      });
    }
    if (actions.sendToValidate) {
      result.push({
        label: 'commons.save_send_to_validate',
        icon: 'fas fa-check-double',
        actionType: ProductionActionEnum.SEND_TO_VALIDATE,
      });
    }
    if (actions.validate) {
      result.push({
        label: 'commons.save_validate',
        icon: 'fas fa-check-double',
        actionType: ProductionActionEnum.VALIDATE,
      });
    }

    // Disabilito il pulsante di conferma se non si possono fare azioni
    const disableActions = Object.values(actions).every(item => item === false);
    this.disableActions$.next(disableActions);

    // Disabilito il form se non ho i permessi per editare
    if (actions.edit === true) {
      this.harvestForm.enable();
    } else {
      this.harvestForm.disable();
    }

    this.actionItems = result;
  }

  private handleValidationSuccess(): void {
    this.eventBusService.emit(new EventData(EventDataEnum.ReloadOperations));
    this.notification.success('harvests.success_validate');
    this.loaderService.hideLoader();
    this.navigateBack();
  }

  private handleValidationError(err: any): void {
    if (err) {
      this.notification.error('commons.error');
    }
    this.loaderService.hideLoader();
  }

  /**
   * Initialize Form
   *
   * @returns
   */
  private initForm(cropCycle: CropCycle): void {
    const harvestForm = new FormGroup<IForm>({
      id: new FormControl(undefined, {
        nonNullable: true,
        validators: this.isEdit ? [Validators.required] : null,
      }),
      harvestDate: new FormControl(undefined, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      notes: new FormControl('', {
        nonNullable: true,
      }),
    });

    // Init values
    if (this.isEdit) {
      harvestForm.patchValue({
        id: this.harvestLot?.id,
        harvestDate: moment(this.harvestLot.harvestDate).startOf('day').toDate(),
        notes: this.harvestLot.notes,
      });
    }

    this.harvestForm = harvestForm;

    this.initCheckChangedForm(this.harvestForm);
    this.cropCycle$.next(cropCycle);
  }

  /**
   * Create HarvestLot from Form
   *
   * @returns
   */
  private makeHarvestLot(): Harvest | null {
    const cropCycle = this.cropCycle$.getValue();
    if (this.harvestForm == null || cropCycle == null) {
      return null;
    }

    const formValue = this.harvestForm.getRawValue();

    const companyInfoId = this.currentCompany.masterCompany
      ? this.currentCompany.masterCompany.id
      : this.currentCompany.id;

    const harvestLot: Harvest = {
      id: formValue.id,
      harvestDate: `${moment(formValue.harvestDate || new Date())
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')}`,
      receivingDate: `${moment(formValue.harvestDate || new Date()).format('YYYY-MM-DD HH:mm:ss')}`,
      companyInfo: { id: companyInfoId } as CompanyInfo,
      cropCycle: { id: cropCycle.id } as CropCycle,
      notes: formValue.notes,
      modificationDate: this.harvestLot?.modificationDate,
    };
    return harvestLot;
  }
}
