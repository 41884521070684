import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ProductionActionEnum } from 'src/app/core/models/action';
import { ActionItem } from '../../models/action-item.model';

@Component({
  selector: 'app-crop-cycle-actions',
  templateUrl: './crop-cycle-actions.component.html',
  styleUrls: ['./crop-cycle-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropCycleActionsComponent {
  @Input() public actionItems: ActionItem[];

  constructor(private popoverController: PopoverController) {}

  async closePopover(actionType: ProductionActionEnum): Promise<void> {
    await this.popoverController.dismiss({
      actionType,
    });
  }
}
