<ion-content class="popover-content">
  <ion-list lines="none">
    <ion-item *ngFor="let action of actionItems" (click)="closePopover(action.actionType)">
      <div class="action-content">
        <div class="icon-action">
          <i [class]="action.icon"></i>
        </div>
        <div class="label-text">{{ action.label | translate }}</div>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
