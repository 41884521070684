export class LanguageEnum {
  public static readonly ENGLISH = new LanguageEnum('ENGLISH', 'en', 'gb');
  public static readonly ITALIAN = new LanguageEnum('ITALIAN', 'it', 'it');

  private constructor(
    public readonly value: string,
    public readonly langCode: string,
    public readonly flagCode: string
  ) {}
}

export const availableLanguages: LanguageEnum[] = Object.values(LanguageEnum);

export const getLanguageFromCode = (langCode?: string): LanguageEnum => {
  if (langCode == null) {
    return LanguageEnum.ITALIAN;
  }
  return availableLanguages.find(l => l.langCode === langCode) || LanguageEnum.ITALIAN;
};
