import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EventData, EventDataEnum } from '../models/event.class';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  public refreshToken$ = new Subject<boolean>();
  private subject$ = new Subject<EventData>();

  constructor() {}

  emit(event: EventData): void {
    this.subject$.next(event);
  }

  on(eventName: EventDataEnum, action: any): Subscription {
    return (
      this.onObservable(eventName)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .subscribe(action)
    );
  }

  onObservable<T>(eventName: EventDataEnum): Observable<T> {
    return this.subject$.pipe(
      filter((e: EventData) => e.name === eventName),
      map((e: EventData) => e.value)
    );
  }
}
