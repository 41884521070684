/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from '../services/sessions.service';

const canActivate: CanActivateFn = () => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  let userAuthorized = false;
  if (sessionService.isLoggedIn.getValue()) {
    userAuthorized = true;
  } else {
    return router.createUrlTree(['public']);
  }
  return userAuthorized;
};

export const canActivateLoggedInGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
