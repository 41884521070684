<app-toolbar-header
  [toolbarType]="(disableActions$ | async) ? 'navigation' : 'navConfirm'"
  (toolbarClick)="onConfirmClick($event)"
  [disableRightButton]="harvestForm && harvestForm.invalid"></app-toolbar-header>

<ion-content *ngIf="cropCycle$ | async as cropCycle">
  <!-- TITLE -->
  <div class="title-header">
    <ion-label class="Title c-dark-1">
      {{ (isEdit ? 'harvests.edit_harvest' : 'harvests.add_harvest') | translate }}
    </ion-label>
    <ion-label class="H4 c-gray-1"> {{ cropCycle.cropType?.name }} {{ cropCycle.cultivarType }} </ion-label>
  </div>

  <!-- INFO EDIT -->
  <ion-grid *ngIf="isEdit">
    <ion-row>
      <ion-col size="12">
        <div class="operation-info">
          <div class="Content-3 c-dusk">
            <i class="fas fa-calendar-alt"></i>
            <span
              >{{ 'commons.modification_date' | translate }}: {{ harvestLot.modificationDate | localizedDate }}</span
            >
          </div>
          <div class="Content-3 c-dusk">
            <i class="fas fa-map-marker-alt"></i>
            <span>{{ 'commons.modification_username' | translate }}: {{ harvestLot.modificationUsername }}</span>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- FORM -->
  <div class="form-container">
    <form [formGroup]="harvestForm" *ngIf="harvestForm">
      <ion-list lines="none">
        <!-- COMPANY RICEVENTE -->
        <div class="recipient-company" *ngIf="currentCompany.masterCompany">
          <div class="Content-3">{{ 'harvests.recipient_company' | translate }}</div>
          <div class="label-text">{{ currentCompany.masterCompany.companyName }}</div>
        </div>

        <!-- DATA INIZIO -->
        <input-datetime
          #dataHarvestInput
          [showDefaultButtons]="true"
          [label]="'harvests.harvest_date' | translate"
          presentation="date"
          formControlName="harvestDate"></input-datetime>

        <!-- NOTE -->
        <input-textarea formControlName="notes" [label]="'harvests.notes' | translate"></input-textarea>

        <ion-item *ngIf="operationActions$ | async as operationActions">
          <ion-button
            style="width: 100%"
            mode="ios"
            fill="clear"
            color="danger"
            expand="block"
            *ngIf="operationActions.remove"
            (click)="removeHarvest()">
            {{ 'harvests.remove' | translate }}
          </ion-button>
        </ion-item>
      </ion-list>
    </form>
  </div>
</ion-content>
