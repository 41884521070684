import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription, map } from 'rxjs';
import { IChangePassword } from 'src/app/core/models/change-password.model';
import { ChangePasswordForm, ControlsOf } from 'src/app/core/models/typed-forms.interface';
import { UtilityHelper } from '../../helpers/utility.helper';

@Component({
  selector: 'app-cambio-password-modal',
  templateUrl: './cambio-password-modal.component.html',
  styleUrls: ['./cambio-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CambioPasswordModalComponent implements OnDestroy {
  changePasswordForm!: FormGroup<ControlsOf<ChangePasswordForm>>;
  public formErrors: any = {};
  private sub: Subscription = new Subscription();

  constructor(private modalCtrl: ModalController) {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async changePassword(): Promise<void> {
    const formValue = this.changePasswordForm.getRawValue();
    const result: IChangePassword = {
      newPassword: formValue.newPassword,
      oldPassword: formValue.oldPassword,
    };
    await this.modalCtrl.dismiss(result);
  }

  private initForm(): void {
    this.changePasswordForm = new FormGroup<ControlsOf<ChangePasswordForm>>(
      {
        oldPassword: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(UtilityHelper.validatePassword)],
        }),
        newPassword: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(UtilityHelper.validatePassword)],
        }),
        confirmPassword: new FormControl('', {
          nonNullable: true,
          validators: [Validators.required, Validators.pattern(UtilityHelper.validatePassword)],
        }),
      },
      { validators: [this.checkEqualsPasswords, this.checkOldSameNewPasswords] }
    );

    const formChange = this.changePasswordForm.valueChanges
      .pipe(
        map(values => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Object.keys(values).forEach(val => {
            /* const current = UtilityHelper.checkError(val, this.changePasswordForm);
            this.formErrors[val] = current === 'validators.pattern' ? 'validators.pattern_password' : current;*/
            this.formErrors[val] = UtilityHelper.checkError(val, this.changePasswordForm);
          });

          if (this.changePasswordForm.hasError('oldNewEquals')) {
            this.formErrors.newPassword = 'validators.password_old_new_equals';
          }

          if (this.changePasswordForm.hasError('notSame')) {
            this.formErrors.confirmPassword = 'validators.password_not_match';
          }
        })
      )
      .subscribe();

    this.sub.add(formChange);
  }

  private checkEqualsPasswords: ValidatorFn = (): ValidationErrors | null => {
    if (this.changePasswordForm == null) return null;
    const pass = this.changePasswordForm.controls.newPassword.value;
    const confirmPass = this.changePasswordForm.controls.confirmPassword.value;
    if (!confirmPass) return null;
    return pass === confirmPass ? null : { notSame: true };
  };

  private checkOldSameNewPasswords: ValidatorFn = (): ValidationErrors | null => {
    if (this.changePasswordForm == null) return null;
    const pass = this.changePasswordForm.controls.oldPassword?.value;
    const newPassword = this.changePasswordForm.controls.newPassword.value;
    if (!newPassword) return null;
    return pass !== newPassword ? null : { oldNewEquals: true };
  };
}
