/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Injector,
  INJECTOR,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { IonModal } from '@ionic/angular';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilityHelper } from 'src/app/shared/helpers/utility.helper';

@Component({
  selector: 'input-datetime',
  templateUrl: 'input-datetime.component.html',
  styleUrls: ['./input-datetime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatetimeComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputDatetimeComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {
  /**
   * The text to display on the picker's cancel button.
   */
  @Input()
  cancelText = 'commons.cancel';
  /**
   * The text to display on the picker's "Clear" button.
   */
  @Input()
  clearText = 'commons.clear';
  /**
   * The color to use from your application's color palette. Default options are: `"primary"`, `"secondary"`, `"tertiary"`, `"success"`, `"warning"`, `"danger"`, `"light"`, `"medium"`, and `"dark"`. For more information on colors, see [theming](/docs/theming/basics).
   */
  @Input()
  color = 'primary';
  /**
   * Values used to create the list of selectable days. By default every day is shown for the given month. However, to control exactly which days of the month to display, the `dayValues` input can take a number, an array of numbers, or a string of comma separated numbers. Note that even if the array days have an invalid number for the selected month, like `31` in February, it will correctly not show days which are not valid for the selected month.
   */
  @Input()
  dayValues?: number[] | number | string;
  /**
   * If `true`, the user cannot interact with the datetime.
   */
  @Input()
  disabled = false;
  /**
   * The text to display on the picker's "Done" button.
   */
  @Input()
  doneText = 'commons.done';
  /**
   * The first day of the week to use for `ion-datetime`. The default value is `0` and represents Sunday.
   */
  @Input()
  firstDayOfWeek = 0;
  /**
   * The hour cycle of the `ion-datetime`. If no value is set, this is specified by the current locale.
   */
  @Input()
  hourCycle?: 'h23' | 'h12';
  /**
   * Values used to create the list of selectable hours. By default the hour values range from `0` to `23` for 24-hour, or `1` to `12` for 12-hour. However, to control exactly which hours to display, the `hourValues` input can take a number, an array of numbers, or a string of comma separated numbers.
   */
  @Input()
  hourValues?: number[] | number | string;
  /**
   * The maximum datetime allowed. Value must be a date string following the [ISO 8601 datetime format standard](https://www.w3.org/TR/NOTE-datetime), `1996-12-19`. The format does not have to be specific to an exact datetime. For example, the maximum could just be the year, such as `1994`. Defaults to the end of this year.
   */
  @Input()
  max?: string;
  /**
   * The minimum datetime allowed. Value must be a date string following the [ISO 8601 datetime format standard](https://www.w3.org/TR/NOTE-datetime), such as `1996-12-19`. The format does not have to be specific to an exact datetime. For example, the minimum could just be the year, such as `1994`. Defaults to the beginning of the year, 100 years ago from today.
   */
  @Input()
  min?: string;
  /**
   * Values used to create the list of selectable minutes. By default the minutes range from `0` to `59`. However, to control exactly which minutes to display, the `minuteValues` input can take a number, an array of numbers, or a string of comma separated numbers. For example, if the minute selections should only be every 15 minutes, then this input value would be `minuteValues="0,15,30,45"`.
   */
  @Input()
  minuteValues?: number[] | number | string;
  /**
   * The mode determines which platform styles to use.
   */
  @Input()
  mode?: 'ios' | 'md';
  /**
   * Values used to create the list of selectable months. By default the month values range from `1` to `12`. However, to control exactly which months to display, the `monthValues` input can take a number, an array of numbers, or a string of comma separated numbers. For example, if only summer months should be shown, then this input value would be `monthValues="6,7,8"`. Note that month numbers do *not* have a zero-based index, meaning January's value is `1`, and December's is `12`.
   */
  @Input()
  monthValues?: number[] | number | string;
  /**
   * The name of the control, which is submitted with the form data.
   */
  // @Input()
  // name: string;
  /**
   * If `true`, a wheel picker will be rendered instead of a calendar grid where possible. If `false`, a calendar grid will be rendered instead of a wheel picker where possible.  A wheel picker can be rendered instead of a grid when `presentation` is one of the following values: `"date"`, `"date-time"`, or `"time-date"`.  A wheel picker will always be rendered regardless of the `preferWheel` value when `presentation` is one of the following values: `"time"`, `"month"`, `"month-year"`, or `"year"`.
   */
  @Input()
  preferWheel = false;
  /**
   * Which values you want to select. `"date"` will show a calendar picker to select the month, day, and year. `"time"` will show a time picker to select the hour, minute, and (optionally) AM/PM. `"date-time"` will show the date picker first and time picker second. `"time-date"` will show the time picker first and date picker second.
   */
  @Input()
  presentation: 'date-time' | 'time-date' | 'date' | 'time' | 'month' | 'year' | 'month-year' = 'date-time';
  /**
   * If `true`, the datetime appears normal but is not interactive.
   */
  @Input()
  readonly = false;
  /**
   * If `true`, a "Clear" button will be rendered alongside the default "Cancel" and "OK" buttons at the bottom of the `ion-datetime` component. Developers can also use the `button` slot if they want to customize these buttons. If custom buttons are set in the `button` slot then the default buttons will not be rendered.
   */
  @Input()
  showClearButton = false;
  /**
   * If `true`, the default "Cancel" and "OK" buttons will be rendered at the bottom of the `ion-datetime` component. Developers can also use the `button` slot if they want to customize these buttons. If custom buttons are set in the `button` slot then the default buttons will not be rendered.
   */
  @Input()
  showDefaultButtons = false;
  /**
   * If `true`, the default "Time" label will be rendered for the time selector of the `ion-datetime` component. Developers can also use the `time-label` slot if they want to customize this label. If a custom label is set in the `time-label` slot then the default label will not be rendered.
   */
  @Input()
  showDefaultTimeLabel = false;
  /**
   * If `true`, a header will be shown above the calendar picker. This will include both the slotted title, and the selected date.
   */
  @Input()
  showDefaultTitle = false;
  /**
   * If `cover`, the `ion-datetime` will expand to cover the full width of its container. If `fixed`, the `ion-datetime` will have a fixed width.
   */
  @Input()
  size: 'cover' | 'fixed' = 'fixed';
  /**
   * The value of the datetime as a valid ISO 8601 datetime string. This should be an array of strings only when `multiple="true"`.
   */
  @Input()
  value?: Date | Date[] | null;
  /**
   * Values used to create the list of selectable years. By default the year values range between the `min` and `max` datetime inputs. However, to control exactly which years to display, the `yearValues` input can take a number, an array of numbers, or string of comma separated numbers. For example, to show upcoming and recent leap years, then this input's value would be `yearValues="2024,2020,2016,2012,2008"`.
   */
  @Input()
  yearValues?: number[] | number | string;

  @Input()
  label: string;

  sub = new Subscription();

  locale = 'it-IT';

  textFormControl: FormControl<string> = new FormControl<string>('');
  error$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private formControl: FormControl<Date>;

  constructor(@Inject(INJECTOR) private injector: Injector, private cd: ChangeDetectorRef) {}

  getCurrentISO(): string | undefined {
    return this.formControl?.value ? moment(this.formControl.value).toISOString() : undefined;
  }

  setDate(ev: any): void {
    if (ev.detail.value && this.formControl) {
      const newDate = moment(ev.detail.value).toDate();
      this.formControl.patchValue(newDate);
      this.setDateTimeText(newDate);
    }
  }

  async inputFocus(modal: IonModal): Promise<void> {
    if (this.formControl.disabled) {
      return;
    }
    await modal.present();
    this.checkError();
  }

  ngAfterViewInit(): void {
    const formControl = this.injector.get(NgControl).control as FormControl;
    if (formControl) {
      this.formControl = formControl;
    } else {
      this.formControl = new FormControl(new Date());
    }

    let sub;

    this.checkError();
    this.textFormControl.markAsTouched();

    if (this.formControl.parent) {
      sub = this.formControl.parent.valueChanges.subscribe(() => {
        this.checkError();
      });
    } else {
      sub = this.formControl.valueChanges.subscribe(() => {
        this.checkError();
      });
    }

    this.checkEnableDisable();

    const sub2 = this.formControl.statusChanges.subscribe(() => {
      this.checkEnableDisable();
    });

    this.sub.add(sub);
    this.sub.add(sub2);
    this.cd.detectChanges();
  }

  public changeValue(args: CustomEvent): void {
    this.writeValue(args.detail.value);
    this.onTouch();
  }

  // Allow Angular to set the value on the component
  public writeValue(value: Date): void {
    this.onChange(value);
    this.setDateTimeText(value);
    this.value = value;
  }

  // Save a reference to the change function passed to us by
  // the Angular form control
  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  // Save a reference to the touched function passed to us by
  // the Angular form control
  public registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }

  // Allow the Angular form control to disable this input
  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: Function = (value: string) => {};
  onTouch: Function = () => {};

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private setDateTimeText(value: Date): void {
    if (value == null) {
      this.textFormControl.patchValue('');
      return;
    }
    const currentDate = moment(value).locale('it');

    if (!currentDate.isValid()) {
      this.textFormControl.patchValue('');
      return;
    }

    switch (this.presentation) {
      case 'date-time':
      case 'time-date':
        const dateText = currentDate.format('L');
        const timeText = currentDate.format('LT');
        this.textFormControl.patchValue(`${dateText} ${timeText}`);
        break;
      case 'date':
        this.textFormControl.patchValue(currentDate.format('L'));

        break;
      case 'time':
        this.textFormControl.patchValue(currentDate.format('LT'));
        break;
      case 'month-year':
        this.textFormControl.patchValue(currentDate.format('LL'));
        break;
      case 'month':
        this.textFormControl.patchValue(currentDate.format('MMMM'));
        break;
      case 'year':
        this.textFormControl.patchValue(currentDate.format('YYYY'));
        break;
    }
  }

  private checkError(): void {
    const error = UtilityHelper.checkFormControlError(this.formControl);

    if (error) {
      this.textFormControl.setErrors(this.formControl.errors);
    }
    this.error$.next(error);
  }

  private checkEnableDisable(): void {
    if (this.formControl.disabled && this.textFormControl.enabled) {
      this.textFormControl.disable();
    } else if (this.formControl.enabled && this.textFormControl.disabled) {
      this.textFormControl.enable();
    }
  }
}
