import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private spinnerContainer?: HTMLElement;

  public setSpinnerContainer(spinner: HTMLElement): void {
    this.spinnerContainer = spinner;
  }

  public showLoader(): void {
    this.isLoading.next(true);
    if (this.spinnerContainer) {
      this.spinnerContainer.style.display = 'flex';
    }
  }

  public hideLoader(): void {
    this.isLoading.next(false);
    if (this.spinnerContainer) {
      this.spinnerContainer.style.display = 'none';
    }
  }
}
