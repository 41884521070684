import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './sessions.service';
import { CropFilter, CropSort, Harvest, ProcessingStateCodeEnum } from '../models/supply-chain.interface';
import { PageableOptions } from '../models/others-interfaces';
import { Observable, map } from 'rxjs';
import { PaginatedList } from '../models/paginated.interface';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../models/supply-chain-validation.interface';

@Injectable({
  providedIn: 'root',
})
export class HarvestLotService {
  constructor(private http: HttpClient, private sessionService: SessionService) {}

  /**
   * Recupera la lista paginata di Harvest
   */
  public getHarvestsByCropCycleId(
    cropCycleId: number,
    filters: CropFilter,
    options: PageableOptions,
    cropSort: CropSort
  ): Observable<PaginatedList<Harvest>> {
    const url = `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/crop-cycles/${cropCycleId}/harvest-lots`;
    let httpParams = new HttpParams();
    let query = '';
    if (filters.stringSearch) {
      query += `companyInfo.companyName ~ '*${filters.stringSearch}*' OR notes ~ '*${filters.stringSearch}*'`;
    }

    // L'azienda è attiva se non c'è data di scandenza
    // oppure se non è scaduta
    if (filters.processingState != null) {
      query += query ? ' AND ' : '';
      query += `processingState : ${filters.processingState?.id}`;
    }

    if (query) {
      httpParams = httpParams.append('q', query);
    }

    if (options.size) {
      httpParams = httpParams.append('size', options.size);
    }

    if (options.page != null) {
      httpParams = httpParams.append('page', options.page);
    }

    if (cropSort.sortField && cropSort.sortOrder != null) {
      const sort = `${cropSort.sortField},${cropSort.sortOrder === 1 ? 'ASC' : 'DESC'}`;
      httpParams = httpParams.append('sort', sort);
    }

    return this.http
      .get(url, {
        params: httpParams,
      })
      .pipe(
        map(response => {
          return response as PaginatedList<Harvest>;
        })
      );
  }

  /**
   * Recupera un lotto di raccolta tramite id
   *
   * @param id
   * @returns
   */
  public getHarvestById(id: number): Observable<Harvest> {
    return this.http
      .get(`${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/harvest-lots/${id}`)
      .pipe(
        map(response => {
          return response as Harvest;
        })
      );
  }

  /**
   * Crea un lotto di raccolta
   *
   * @param harvest
   * @returns
   */
  public createHarvest(harvest: Harvest): Observable<Harvest> {
    const url = `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/harvest-lots`;
    return this.http.post(url, harvest).pipe(
      map(response => {
        return response as Harvest;
      })
    );
  }

  /**
   * Aggiorna le informazioni di un lotto di raccolta
   *
   * @param harvest
   * @returns
   */
  public updateHarvest(harvest: Harvest): Observable<Harvest> {
    if (harvest.id == null) {
      throw new Error('No harvest id');
    }
    const url = `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/harvest-lots/${harvest.id}`;
    return this.http.patch(url, harvest).pipe(
      map(response => {
        return response as Harvest;
      })
    );
  }

  /**
   * Invia un lotto di raccolta per la validazione
   *
   * @param harvest
   * @returns
   */
  public sendToValidationHarvest(harvest: Harvest): Observable<Harvest> {
    if (harvest.id == null) {
      throw new Error('No harvest id');
    }
    const url = `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/harvest-lots/${harvest.id}/set-status-to-validate`;
    return this.http.put(url, harvest).pipe(
      map(response => {
        return response as Harvest;
      })
    );
  }

  /**
   * Invia l'harvest in blockchain
   *
   * @param harvestLot
   * @returns
   */
  public sendHarvestInBlockChain(harvestLot: Harvest): Observable<void> {
    if (harvestLot.id == null) {
      throw new Error('No harvest id');
    }
    const url = `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/crop-cycle/${harvestLot.cropCycle.id}/send-in-blockchain/harvest-lot/${harvestLot.id}`;
    return this.http.put<ResponseMessage<string>>(url, harvestLot).pipe(
      map(response => {
        if (response.message !== 'OK') {
          throw new Error(response.message);
        }
        return;
      })
    );
  }

  /**
   * Richiede la riapertura per la modifica di un lotto di raccolta
   *
   * @param harvest
   * @returns
   */
  public openCorrectionHarvest(harvest: Harvest): Observable<Harvest> {
    if (harvest.id == null) {
      throw new Error('No harvest id');
    }
    const url = `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/harvest-lots/${harvest.id}/set-inserted`;
    return this.http.put(url, harvest).pipe(
      map(response => {
        return response as Harvest;
      })
    );
  }

  /**
   * Elimina un lotto di raccolta
   *
   * @param harvestId
   * @returns
   */
  public removeHarvest(harvest: Harvest): Observable<Harvest> {
    const url = `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/harvest-lots/${harvest.id}`;
    return this.http.delete(url, { body: harvest }).pipe(
      map(response => {
        return response as Harvest;
      })
    );
  }

  /**
   * Restituisce true se ci sono harvest lots del crop-cycle in stato "Da Validare"
   *
   * @param cropCycle Crop Cycle
   * @returns true/false
   */
  public existOperationInStatusD(cropCycleId: number): Observable<boolean> {
    return this.existOperationInStatus(ProcessingStateCodeEnum.DA_VALIDARE, cropCycleId);
  }

  /**
   * Restituisce il totale degli harvest lots del crop-cycle in stato "Da Validare"
   *
   * @param cropCycle Crop Cycle
   * @returns total count
   */
  public countOperationInStatusD(cropCycleId: number): Observable<number> {
    return this.countOperationInStatus(ProcessingStateCodeEnum.DA_VALIDARE, cropCycleId);
  }

  /**
   * Restituisce true e ci sono harvest lots nello stato ricercato
   *
   * @param state ProcessingStateCodeEnum
   * @param cropCycle Crop Cycle
   * @returns true/false
   */
  public existOperationInStatus(state: ProcessingStateCodeEnum, cropCycleId: number): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/crop-cycles/${cropCycleId}/harvest-lots/state/${state}/exists`
    );
  }

  /**
   * Restituisce il totale degli harvest lots nello stato ricercato
   *
   * @param state ProcessingStateCodeEnum
   * @param cropCycle Crop Cycle
   * @returns total count
   */
  public countOperationInStatus(state: ProcessingStateCodeEnum, cropCycleId: number): Observable<number> {
    return this.http.get<number>(
      `${environment.gravitee}/api-supply-chain/companies/${this.sessionService.currentClient}/crop-cycles/${cropCycleId}/harvest-lots/state/${state}/count`
    );
  }
}
