/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private datePipe: DatePipe) {}

  transform(value: any, pattern: string = 'shortDate'): any {
    const setLanguage = this.translateService.currentLang || this.translateService.defaultLang;
    return this.datePipe.transform(value, pattern, undefined, setLanguage);
  }
}
