import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ExpandableTextComponent } from './components/expandable-text/expandable-text.component';
import { TagStateComponent } from './components/tag-state/tag-state.component';
import { ToolbarHeaderModule } from './components/toolbar-header/toolbar-header.module';
import { CompanyAddressPipe } from './pipes/company-address.pipe';
import { LocalizedContentTextPipe } from './pipes/localized-content-text.pipe';
import { LocalizedDatePipe } from './pipes/localized-date-pipe';
import { LocalizedSupplychainPipe } from './pipes/localized-supplychain.pipe';
import { PercentualCompletedPipe } from './pipes/percentual-completed.pipe';

@NgModule({
  declarations: [
    ExpandableTextComponent,
    TagStateComponent,
    LocalizedContentTextPipe,
    CompanyAddressPipe,
    LocalizedSupplychainPipe,
    LocalizedDatePipe,
    PercentualCompletedPipe,
  ],
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule, ToolbarHeaderModule],
  exports: [
    ToolbarHeaderModule,
    ExpandableTextComponent,
    LocalizedContentTextPipe,
    CompanyAddressPipe,
    LocalizedSupplychainPipe,
    LocalizedDatePipe,
    TagStateComponent,
    PercentualCompletedPipe,
  ],
  providers: [
    LocalizedContentTextPipe,
    LocalizedDatePipe,
    CompanyAddressPipe,
    LocalizedSupplychainPipe,
    PercentualCompletedPipe,
  ],
})
export class SharedModule {}
