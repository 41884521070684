<app-toolbar-header toolbarType="navigation"></app-toolbar-header>

<ion-content *ngIf="cropCycle$ | async as cropCycle">
  <ion-fab slot="fixed" vertical="bottom" horizontal="end" *ngIf="enableNewOperations$ | async">
    <ion-fab-button (click)="addHarvest()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="title-header">
    <ion-label class="Title c-dark-1"> {{ 'crop_cycle.operations.harvests' | translate }} </ion-label>
    <ion-label class="H4 c-gray-1"> {{ cropCycle.cropType?.name }} {{ cropCycle.cultivarType }} </ion-label>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <div class="crop-info">
          <div class="Content-3 c-dusk">
            <i class="fas fa-calendar-alt"></i>
            <span>{{ 'crop_cycle.starting_date' | translate }}: {{ cropCycle.startingDate | localizedDate }}</span>
          </div>
          <div class="Content-3 c-dusk">
            <i class="fas fa-map-marker-alt"></i>
            <span>{{ cropCycle.parcel?.parcelName }}</span>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-list lines="none" *ngIf="harvestLots?.length > 0; else noNoHarvestLots">
    <app-card-harvest-lot
      [harvest]="harvestLot"
      *ngFor="let harvestLot of harvestLots"
      (click)="editHarvest(harvestLot)">
    </app-card-harvest-lot>
  </ion-list>
  <ion-infinite-scroll (ionInfinite)="onIonInfinite()">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ng-template #noNoHarvestLots>
  <div class="empty-content">
    <ion-label>{{ 'commons.no_data' | translate }}</ion-label>
  </div>
</ng-template>
