<ion-item lines="none" button [detail]="false" class="item-operation">
  <div class="operation-container">
    <div class="operation-top">
      <div class="operation-name">
        {{ 'harvests.harvest' | translate }}
      </div>
      <div class="operation-status">
        <app-tag-state [state]="harvest.processingState"></app-tag-state>
      </div>
    </div>
    <div class="operation-bottom">
      <p>{{ 'harvests.harvest_date' | translate }}: {{ harvest.harvestDate | localizedDate }}</p>
      <p *ngIf="harvest.receivingDate">
        {{ 'harvests.receiving_date' | translate }}: {{ harvest.receivingDate | localizedDate }}
      </p>
    </div>
  </div>
</ion-item>
