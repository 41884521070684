import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, NavController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription, map, of, switchMap, tap } from 'rxjs';
import { LazyCropCycleService } from 'src/app/authenticated/services/lazy-crop-cycle.service';
import { EventDataEnum } from 'src/app/core/models/event.class';
import { PageableOptions } from 'src/app/core/models/others-interfaces';
import {
  CropCycle,
  CropFilter,
  CropSort,
  Harvest,
  ProcessingState,
  ProcessingStateCodeEnum,
  SortOrderEnum,
} from 'src/app/core/models/supply-chain.interface';
import { EventBusService } from 'src/app/core/services/event-bus.service';
import { HarvestLotService } from 'src/app/core/services/harvest-lot.service';
import { SessionService } from 'src/app/core/services/sessions.service';
import { SupplyChainService } from 'src/app/core/services/supply-chain.service';

@Component({
  selector: 'app-harvest-lots',
  templateUrl: './harvest-lots.component.html',
  styleUrls: ['./harvest-lots.component.scss'],
})
export class HarvestLotsComponent implements OnInit, OnDestroy {
  @ViewChild(IonInfiniteScroll)
  public infiniteScroll: IonInfiniteScroll;

  public harvestLots: Harvest[] = [];

  public pageableOptions: PageableOptions = {
    size: 15,
    page: 0,
  };

  public stringSearch = '';
  public filterProcessingState?: ProcessingState;
  public filterRangeDates: Date[] = [];
  public sort: CropSort = {
    sortField: 'harvestDate',
    sortOrder: SortOrderEnum.DESC,
  };
  public isValidator: boolean;
  public enableNewOperations$ = new BehaviorSubject<boolean>(false);

  private totalElements = 0;
  private subs = new Subscription();
  private proccessingStateMap?: Map<ProcessingStateCodeEnum, number>;

  constructor(
    private lazyService: LazyCropCycleService,
    private navCtrl: NavController,
    private sessionService: SessionService,
    private eventBusService: EventBusService,
    private harvestLotService: HarvestLotService,
    private cd: ChangeDetectorRef,
    private supplyChainService: SupplyChainService
  ) {
    this.isValidator = this.sessionService.hasValidatorPermissions;
    const reloadSub = this.eventBusService.on(EventDataEnum.ReloadOperations, () => {
      this.getPaginatedHarvestLots(true).subscribe(() => {});
    });

    this.subs.add(reloadSub);
  }

  public get cropCycle$(): BehaviorSubject<CropCycle | undefined> {
    return this.lazyService.cropCycle$;
  }

  async ngOnInit(): Promise<void> {
    const currentCropCycle = this.cropCycle$.getValue();
    if (currentCropCycle == null) {
      await this.navCtrl.navigateBack('/authenticated');
      return;
    }
    this.supplyChainService
      .getProcessingStateMap()
      .pipe(
        tap(pMap => (this.proccessingStateMap = pMap)),
        switchMap(() => this.getPaginatedHarvestLots(true))
      )
      .subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onIonInfinite(): void {
    if (this.checkCompleteInfiniteScroll()) {
      return;
    }

    const currentPage = parseInt(`${this.harvestLots.length / (this.pageableOptions.size + 1)}`, 10);

    this.pageableOptions.page = currentPage + 1;
    this.getPaginatedHarvestLots().subscribe(() => {});
  }

  async addHarvest(): Promise<void> {
    await this.navCtrl.navigateForward(`/authenticated/crop-cycle/${this.cropCycle$.getValue().id}/harvest-lots/new`);
  }

  async editHarvest(harvest: Harvest): Promise<void> {
    await this.navCtrl.navigateForward(
      `/authenticated/crop-cycle/${this.cropCycle$.getValue().id}/harvest-lots/${harvest.id}`,
      {
        state: {
          harvest,
        },
      }
    );
  }

  private getPaginatedHarvestLots(isReload = false): Observable<Harvest[]> {
    const currentCropCycle = this.cropCycle$.getValue();

    if (currentCropCycle == null) {
      return of([]);
    }

    this.enableNewOperations$.next(
      currentCropCycle.processingState.id === this.proccessingStateMap?.get(ProcessingStateCodeEnum.VALIDATO)
    );

    const cropFilter: CropFilter = {
      stringSearch: '',
    };

    return this.harvestLotService
      .getHarvestsByCropCycleId(currentCropCycle.id, cropFilter, this.pageableOptions, this.sort)
      .pipe(
        map(response => {
          let result: Harvest[] = [];
          if (response != null && response.content != null) {
            result = response.content;
          }
          this.totalElements = response.totalElements || 0;
          return result;
        }),
        tap(hl => {
          if (isReload) {
            this.harvestLots = [];
          }
          this.harvestLots.push(...hl);
          this.checkCompleteInfiniteScroll();
          this.cd.detectChanges();
        })
      );
  }

  private checkCompleteInfiniteScroll(): boolean {
    // Blocco l'infinite scroll se sono arrivato al limite
    if (this.harvestLots.length === this.totalElements) {
      this.infiniteScroll?.complete().finally(() => {});
      return true;
    }
    return false;
  }
}
