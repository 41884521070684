export enum ActionEnum {
  CANCEL = 'cancel',
  FILTER = 'filter',
  SEARCH = 'search',
  UPDATE = 'update',
  ADD = 'add',
  DELETE = 'delete',
  SWITCH = 'switch',
  CONFIRM = 'confirm',
  NEW = 'newEntity',
  USER = 'user',
  DETAIL = 'detail',
}

export enum ProductionActionEnum {
  CANCEL = 'cancel',
  UPDATE = 'update',
  ADD = 'add',
  INSERT = 'insert',
  SEND_TO_VALIDATE = 'send_to_validate',
  OPEN_CORRECTION = 'open_correction',
  VALIDATE = 'validate',
  REMOVE = 'remove',
  SEND_TO_CLOSE = 'send_to_close',
  CLOSE = 'close',
  SEND_ALL_TO_BLOCKCHAIN = 'send_all_to_blockchain',
}
