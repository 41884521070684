import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ClientInfo } from 'src/app/core/models/user.interface';

@Component({
  selector: 'app-cambio-contesto-modal',
  templateUrl: './cambio-contesto-modal.component.html',
  styleUrls: ['./cambio-contesto-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CambioContestoModalComponent {
  @Input() isDismissable = false;
  @Input() clients: ClientInfo[] = [];
  @Input() clientSelezionato?: string;
  @Input() firstName: string;

  constructor(private modalCtrl: ModalController) {}

  async closeModal(): Promise<void> {
    if (!this.isDismissable) {
      return;
    }
    await this.modalCtrl.dismiss();
  }

  async selezionaContesto(client: ClientInfo): Promise<void> {
    await this.modalCtrl.dismiss(client.clientId);
  }
}
