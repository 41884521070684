import { AbstractControl, FormControl, UntypedFormGroup } from '@angular/forms';
import { IrrigationType } from 'src/app/core/models/supply-chain.interface';

export class UtilityHelper {
  public static MAX_CHARACTERS_TEXTAREA = 255;
  public static DAY_MS = 86400000;
  public static WEEK_MS = UtilityHelper.DAY_MS * 7;
  public static MONTH_MS = UtilityHelper.DAY_MS * 30;
  public static YEAR_MS = UtilityHelper.DAY_MS * 365;
  public static noIrrigationType: IrrigationType = {
    id: -1,
    irrigationType: 'Nessuna irrigazione',
    irrigationTypeEn: 'No irrigation',
  };

  public static validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //  https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html
  public static regexVATnumberIT = /\d{11}/;

  /**
   * ^                      # start of the string
   * (?=.*\d)            # a digit must occur at least once
   * (?=.*[a-z])            # a lower case letter must occur at least once
   * (?=.*[A-Z])            # an upper case letter must occur at least once
   * (?=.*[!@#$%^&*()\-_+]) # a special character must occur at least once
   * (?=\S+$)               # no whitespace allowed in the entire string
   * .{8,}                  # at least 8 character password, both inclusive
   * $                      # end of the string
   */
  public static validatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+])(?=\S+$).{8,}$/;
  public static validTypeImage = '.jpg, .png, .jpeg, .jfif, .pjpeg, .pjp';

  public static checkError(nomeCampo: string, formGroup: UntypedFormGroup | undefined): string {
    if (formGroup == null || formGroup.get(nomeCampo) == null) {
      return '';
    }
    return UtilityHelper.checkFormControlError(formGroup.get(nomeCampo));
  }

  public static checkFormControlError(formControl: FormControl | AbstractControl<any, any>): string {
    if (formControl == null) {
      return '';
    }

    if (formControl.hasError('required')) {
      return 'validators.required';
    }
    if (formControl.hasError('minlength')) {
      return 'validators.minLength';
    }
    if (formControl.hasError('maxlength')) {
      return 'validators.maxLength';
    }
    if (formControl.hasError('min') || formControl.hasError('max')) {
      return 'validators.rangeValue';
    }
    if (formControl.hasError('pattern')) {
      return 'validators.pattern';
    }
    if (formControl.hasError('email')) {
      return 'validators.emailNotValid';
    }
    return '';
  }

  public static removeTypeBase64(base64String: string): string {
    base64String = base64String.replace('data:image/jpg;base64,', '');
    base64String = base64String.replace('data:image/jpeg;base64,', '');
    base64String = base64String.replace('data:image/png;base64,', '');
    return base64String;
  }

  public static addTypeToBase64(strBase64: string): string {
    if (strBase64) {
      if (strBase64.startsWith('/')) {
        strBase64 = 'data:image/jpg;base64,' + strBase64;
      } else if (strBase64.startsWith('i')) {
        strBase64 = 'data:image/png;base64,' + strBase64;
      }
      return strBase64;
    }
    return '';
  }

  public static stringIgnoreCaseEndsWith(strText: string, endsWith: string[]): boolean {
    if (strText && endsWith && endsWith.length > 0) {
      for (const str of endsWith) {
        if (strText.toLowerCase().endsWith(str.toLowerCase().trim())) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
}
