/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import { ClientInfo } from '../models/user.interface';
import { AuthToken } from '../models/auth-token.interface';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  public static MASTER_TOKEN_KEY = 'master_token';
  public static OPERATIONAL_TOKEN_KEY = 'operational_token';
  public static USER_CLIENTS_KEY = 'user_clients';
  constructor() {}

  // Token principale per effettuare la login al sistema
  get masterToken(): AuthToken | null {
    const result = localStorage.getItem(UserStorageService.MASTER_TOKEN_KEY);
    return result ? (JSON.parse(result) as AuthToken) : null;
  }

  set masterToken(masterToken: AuthToken | null) {
    if (masterToken) {
      masterToken.expireTime = new Date().getTime() + masterToken.expires_in * 1000 - 3000;
      masterToken.refreshExpireTime = new Date().getTime() + masterToken.refresh_expires_in * 1000 - 3000;
      localStorage.setItem(UserStorageService.MASTER_TOKEN_KEY, JSON.stringify(masterToken));
    } else {
      localStorage.removeItem(UserStorageService.MASTER_TOKEN_KEY);
    }
  }

  // Token operazionale, per effettuare le operazioni in base al client/azienda/ruolo selezionato
  get operationalToken(): AuthToken | null {
    const result = localStorage.getItem(UserStorageService.OPERATIONAL_TOKEN_KEY);
    return result ? (JSON.parse(result) as AuthToken) : null;
  }

  set operationalToken(operationalToken: AuthToken | null) {
    if (operationalToken) {
      operationalToken.expireTime = new Date().getTime() + operationalToken.expires_in * 1000 - 3000;
      operationalToken.refreshExpireTime = new Date().getTime() + operationalToken.refresh_expires_in * 1000 - 3000;
      localStorage.setItem(UserStorageService.OPERATIONAL_TOKEN_KEY, JSON.stringify(operationalToken));
    } else {
      localStorage.removeItem(UserStorageService.OPERATIONAL_TOKEN_KEY);
    }
  }

  get userClients(): ClientInfo[] | undefined {
    const userClients = localStorage.getItem(UserStorageService.USER_CLIENTS_KEY);
    return userClients == null ? null : JSON.parse(userClients);
  }

  set userClients(userClients: ClientInfo[] | undefined) {
    if (userClients) {
      localStorage.setItem(UserStorageService.USER_CLIENTS_KEY, JSON.stringify(userClients));
    } else {
      localStorage.removeItem(UserStorageService.USER_CLIENTS_KEY);
    }
  }

  public checkOldTypeToken(): boolean {
    try {
      const result = localStorage.getItem(UserStorageService.OPERATIONAL_TOKEN_KEY);
      if (result) {
        JSON.parse(result);
      }
    } catch (err) {
      localStorage.clear();
      window.location.reload();
      return true;
    }
    return false;
  }
}
