/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthToken } from '../models/auth-token.interface';
import { EventData, EventDataEnum } from '../models/event.class';
import { NotificationMessage } from '../models/notification-message.interface';
import { EventBusService } from '../services/event-bus.service';
import { UserStorageService } from '../services/user-storage.service';

const TOKEN_HEADER_KEY = 'Authorization'; // for Spring Boot back-end

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private userStorageService: UserStorageService, private eventBusService: EventBusService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Se è un dominio da non controllare, passo avanti
    if (this.bypassInterceptor(req)) {
      return next.handle(req);
    }
    let originalReq = req;

    let usedToken;
    // Per le richieste di switch o se non ho settato l'operationalToken, utilizzo il masterToken
    if (this.isSetTenant(originalReq) || !this.userStorageService.operationalToken) {
      usedToken = this.userStorageService.masterToken;
    } else {
      // Per tutte le altre utilizzo l'operational token
      usedToken = this.userStorageService.operationalToken;
    }

    if (usedToken != null) {
      originalReq = this.addTokenHeader(req, usedToken);
    }

    return next.handle(originalReq).pipe(
      catchError(error => {
        let notified = false;

        if (error.status === 401) {
          // Se non è una chiamata di autenticazione, procedo con la gestione del 401
          if (!this.isAuthRequest(originalReq)) {
            return this.handle401Error(originalReq, next);
          }
          notified = true;
          const notificationData: NotificationMessage = {
            message: 'commons.expired_session',
            type: 'info',
          };
          // Visualizzo il messaggio di sessione scaduta, solo se non è la richiesta di jwt
          if (!this.isLoginRequest(originalReq)) {
            this.eventBusService.emit(new EventData(EventDataEnum.Notification, notificationData));
          }
          this.eventBusService.emit(new EventData(EventDataEnum.Logout));
        } else if (error.status === 400) {
          let notificationData: NotificationMessage;
          if (this.isRefreshTokenRequest(originalReq)) {
            notificationData = {
              message: 'commons.expired_session',
              type: 'info',
            };
            this.eventBusService.emit(new EventData(EventDataEnum.Logout));
          } else {
            const message = error.error && error.error.detail ? error.error.detail : 'errors.bad_request';
            notificationData = {
              message,
              type: 'error',
            };
          }
          this.eventBusService.emit(new EventData(EventDataEnum.Notification, notificationData));
          notified = true;
        }

        const result = notified ? null : error;
        return throwError(result);
      })
    );
  }

  /**
   * E' una richiesta di autenticazione, se l'url contiene jwt, setTenant o refresh-token
   *
   * @param originalReq
   * @returns
   */
  private isAuthRequest(originalReq: HttpRequest<any>): boolean {
    return this.isLoginRequest(originalReq) || this.isRefreshTokenRequest(originalReq) || this.isSetTenant(originalReq);
  }

  /**
   * E' una richiesta di login, se l'url contiene jwt
   *
   * @param originalReq
   * @returns
   */
  private isLoginRequest(originalReq: HttpRequest<any>): boolean {
    return originalReq.url.includes('jwt');
  }

  /**
   * E' una richiesta di refresh token, se l'url contiene refresh-token
   *
   * @param originalReq
   * @returns
   */
  private isRefreshTokenRequest(originalReq: HttpRequest<any>): boolean {
    return originalReq.url.includes('refresh-token');
  }

  /**
   * E' una richiesta di cambio tenant, se l'url contiene setTenant
   *
   * @param originalReq
   * @returns
   */
  private isSetTenant(originalReq: HttpRequest<any>): boolean {
    return originalReq.url.includes('setTenant');
  }

  private handle401Error(originalReq: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const observable = this.eventBusService.onObservable<AuthToken>(EventDataEnum.TokenRefreshed).pipe(
      switchMap(newToken => {
        return next.handle(this.addTokenHeader(originalReq, newToken));
      }),
      catchError(err => {
        this.eventBusService.emit(new EventData(EventDataEnum.Logout));
        throw new Error(err);
      })
    );
    this.eventBusService.refreshToken$.next(true);
    return observable;
  }

  private addTokenHeader(request: HttpRequest<any>, token: AuthToken | null): HttpRequest<any> {
    // Se sono le chiamate di refresh o di login, non aggiungo il token
    if (this.isRefreshTokenRequest(request) || this.isLoginRequest(request)) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, `${token?.token_type} ${token?.access_token}`),
    });
  }

  private bypassInterceptor(request: HttpRequest<any>): boolean {
    return !request.url.startsWith('http') || !this.isJwtAllowedDomain(request);
  }

  private isJwtAllowedDomain(request: HttpRequest<any>): boolean {
    const requestUrl = new URL(request.url, document.location.origin);
    // If the host equals the current window origin,
    // the domain is allowed by default
    if (requestUrl.host === document.location.host) {
      return true;
    }
    // If not the current domain, check the allowed list
    const hostName = `${requestUrl.hostname}${
      requestUrl.port && !['80', '443'].includes(requestUrl.port) ? ':' + requestUrl.port : ''
    }`;
    return environment.jwtAllowedDomains.findIndex(domain => domain === hostName) > -1;
  }
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }];
