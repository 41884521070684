<ion-item
  *ngIf="formControl"
  lines="none"
  class="input-textarea"
  [class.item-interactive-disabled]="formControl.disabled">
  <ion-textarea
    *ngIf="formControl.enabled; else formDisabled"
    [value]="value"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [label]="label"
    aria-label=""
    labelPlacement="floating"
    [errorText]="(error$ | async | translate) || ' '">
  </ion-textarea>

  <ng-template #formDisabled>
    <div class="form-disabled">
      <div class="label">{{ label }}</div>
      <div class="form-value">{{ formControl.value }}</div>
    </div>
  </ng-template>
</ion-item>
