import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percCompleted',
  pure: false,
})
export class PercentualCompletedPipe implements PipeTransform {
  constructor() {}

  transform(value: { total: number; current: number }): string {
    if (value == null || !value.total) {
      return '0%';
    }

    const current = value.current || 0;
    const total = value.total || 1;

    const perc = `${(current * 100) / total}`;

    return `${100 - parseInt(perc, 10)}%`;
  }
}
