import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { getLanguageFromCode, LanguageEnum } from '../models/language.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnDestroy {
  public currentLanguage$: Observable<LanguageEnum>;
  private language: BehaviorSubject<LanguageEnum> = new BehaviorSubject<LanguageEnum>(LanguageEnum.ITALIAN);

  private sub: Subscription = new Subscription();
  private isLoading = true;

  constructor(public translate: TranslateService) {
    this.currentLanguage$ = this.language.asObservable();
    const langChange = this.currentLanguage$.subscribe(newLang => {
      if (!this.isLoading) {
        this.translate.use(newLang.langCode);
        this.translate.setDefaultLang(newLang.langCode);
        moment.locale(newLang.langCode);
        sessionStorage.setItem('language', newLang.langCode);
      } else {
        this.isLoading = false;
      }
    });
    this.sub.add(langChange);

    const lang = sessionStorage.getItem('language');
    if (!lang) {
      sessionStorage.setItem('language', this.language.getValue().langCode);
    } else {
      this.language.next(getLanguageFromCode(lang));
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getLanguage(): LanguageEnum {
    return this.language.getValue();
  }

  setLanguage(lang: LanguageEnum): void {
    this.language.next(lang);
  }
}
