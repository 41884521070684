<ion-card mode="md" *ngIf="clientInfo" class="card-contesto" (click)="onClick.emit(clientInfo)" button>
  <ion-card-content>
    <div class="card-content">
      <div class="img-container" [class.bgplaceholder]="!clientInfo.logo">
        <ion-img *ngIf="clientInfo.logo" [src]="clientInfo.logo"></ion-img>
      </div>
      <div class="content-text">
        <div class="Label-2 c-dark-1">{{ clientInfo.name }}</div>
        <div class="Content-3 c-dusk">
          <span>{{ clientInfo.role ? ('roles.' + clientInfo.role | translate) : '' }}</span>
        </div>
      </div>
    </div>
  </ion-card-content>
</ion-card>
