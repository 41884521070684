// app.module.ts
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';

const browserTracing: any = new SentryAngular.BrowserTracing({
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost'],
  routingInstrumentation: SentryAngular.routingInstrumentation,
});

export const configSentry = {
  dsn: 'https://dbc3568a48da4e8ab7f0842b9153eeb0@sentry.open-lab.cloud/7',
  // To set your release and dist versions
  release: `agriverso-operator-app@${environment.version.commitHash}`,
  environment: environment.envName,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 0.1,
  integrations: [browserTracing],
};

export const sentryCaptureException = (err: string): void => {
  SentryAngular.captureException(err);
};

export const sentryInit = (): void => {
  // Attivo solo se sono in ambiente server (prod, preprod, devregistry)
  // in locale non viene avviato
  if (environment.production) {
    Sentry.init(
      configSentry,
      // Forward the init method from @sentry/angular
      SentryAngular.init
    );
  }
};

export const setSentryUser = (username?: string): void => {
  if (environment.production) {
    Sentry.setUser(username ? { username } : null);
  }
};
