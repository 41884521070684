/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject, Observable, from, of, zip } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { CambioContestoModalComponent } from 'src/app/shared/components/cambio-contesto-modal/cambio-contesto-modal.component';
import { CambioPasswordModalComponent } from 'src/app/shared/components/cambio-password-modal/cambio-password-modal.component';
import { AuthToken } from '../models/auth-token.interface';
import { IChangePassword } from '../models/change-password.model';
import { EventData, EventDataEnum } from '../models/event.class';
import { JwtToken } from '../models/jwt-token.interface';
import { NotificationMessage } from '../models/notification-message.interface';
import { CompanyInfo, CompanyTypeCode } from '../models/supply-chain.interface';
import { LoginForm } from '../models/typed-forms.interface';
import { FrontendUserRolesEnum, UserRolesEnum, userRoleValues } from '../models/user-roles.enum';
import { ClientInfo, User } from '../models/user.interface';
import { CompanyService } from './company.service';
import { EventBusService } from './event-bus.service';
import { LoaderService } from './loader.service';
import { UserStorageService } from './user-storage.service';
import { UserService } from './user.service';
import { setSentryUser } from 'src/app/shared/helpers/sentry.helper';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public isLoggedIn: BehaviorSubject<boolean>;
  public user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public userRoles?: UserRolesEnum[];
  // eslint-disable-next-line prettier/prettier
  public frontendRole$: BehaviorSubject<FrontendUserRolesEnum | undefined> = new BehaviorSubject<
    FrontendUserRolesEnum | undefined
  >(undefined);

  private decodedToken: JwtToken | null = null;

  constructor(
    public userService: UserService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private loaderService: LoaderService,
    private companyService: CompanyService,
    private userStorageService: UserStorageService,
    private eventBusService: EventBusService
  ) {
    this.userStorageService.checkOldTypeToken();
    this.isLoggedIn = new BehaviorSubject<boolean>(!!this.userStorageService.operationalToken);
    this.initRefreshToken();
    this.initSession().subscribe();
    this.user.subscribe(user => {
      setSentryUser(user?.username);
    });
  }

  get currentClient(): string | undefined {
    return this.isAdmin ? UserService.MASTER_CLIENT_ID : this.decodedToken?.aud;
  }

  get currentClientName(): string | undefined {
    return this.userStorageService.userClients?.find(val => val.clientId === this.currentClient)?.name;
  }

  get userClients(): ClientInfo[] | undefined {
    return this.userStorageService.userClients;
  }

  get hasValidatorPermissions(): boolean {
    return (
      this.userRoles?.includes(UserRolesEnum.CLIENT_ADMIN) === true ||
      this.userRoles?.includes(UserRolesEnum.VALIDATORE) === true
    );
  }

  get isAdmin(): boolean {
    return (
      this.userStorageService.operationalToken != null &&
      this.userStorageService.operationalToken.access_token === this.userStorageService.masterToken?.access_token
    );
  }

  /**
   * Effettua la login
   */
  login(loginData: LoginForm): void {
    this.loaderService.showLoader();
    this.userService
      .login(loginData.username, loginData.password)
      .pipe(
        catchError(error => {
          this.loaderService.hideLoader();
          this.notify({
            message: 'login.error',
            type: 'error',
          });
          const message = error || 'Login Error';
          throw new Error(`${message}`);
        }),
        // Scarico la lista di aziende-ruoli dell'utente
        switchMap(masterToken => {
          let username = '';
          let firstName = '';
          if (masterToken && masterToken.access_token) {
            this.userStorageService.masterToken = masterToken;
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken<JwtToken>(masterToken.access_token);
            username = decodedToken?.preferred_username || '';
            firstName = decodedToken?.given_name || '';
            if (!decodedToken?.email_verified) {
              return this.updatePassword(username);
            }
          }
          return this.postLogin(username, firstName);
        })
      )
      .subscribe({
        next: () => {},
        error: () => {
          console.log('Login Error');
        },
      });
  }

  /**
   * Effettua il logout cancellando la sessione dell'utente dallo storage e dal servizio
   */
  logout(): void {
    localStorage.clear();
    this.isLoggedIn.next(false);
    this.decodedToken = null;
    delete this.userStorageService.userClients;
    delete this.userRoles;
    this.frontendRole$.next(undefined);
    this.navCtrl.navigateRoot(['/'], { replaceUrl: true }).finally(() => {});
  }

  /**
   * Presenta la modale di selezione client,
   * ed esegue la logica di switch su un altro client selezionato
   */
  changeCompany(): void {
    this.loaderService.showLoader();
    const username = this.user.getValue()?.username || '';
    const firstName = this.user.getValue()?.firstName || '';
    this.getClientsAndShowModal(username, firstName, true)
      .pipe(
        switchMap(operationalToken => {
          if (operationalToken) {
            this.userStorageService.operationalToken = operationalToken;
            return this.initSession();
          }
          return of(false);
        }),
        catchError(error => {
          this.loaderService.hideLoader();
          this.notify({
            message: 'login.error',
            type: 'error',
          });
          throw new Error(`${error}`);
        })
      )
      .subscribe(result => {
        this.loaderService.hideLoader();
        if (result === false) {
          return;
        }

        this.isLoggedIn.next(true);
        this.navCtrl.navigateRoot(['/'], { replaceUrl: true }).finally(() => {});
        const successMessage =
          this.currentClientName === UserService.MASTER_CLIENT_NAME
            ? 'user_profile.change_to_superadmin_success'
            : 'user_profile.change_company_success';

        this.eventBusService.emit(new EventData(EventDataEnum.ReloadHome));

        this.notify({
          message: successMessage,
          messageParameters: { nome: this.currentClientName },
          type: 'success',
        });
      });
  }

  /**
   * Inizializza le variabili dell'utente in base al JWT
   */
  private initSession(showLoginMessage = false): Observable<boolean> {
    if (this.userStorageService.operationalToken == null) {
      return of(false);
    }
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken<JwtToken>(this.userStorageService.operationalToken.access_token);

    // Controllo la validità del token salvato
    if (helper.isTokenExpired(this.userStorageService.operationalToken.access_token) || decodedToken == null) {
      this.logout();
      return of(false);
    }

    this.decodedToken = decodedToken;

    let getCompany;

    // Se è un ADMIN
    if (this.isAdmin) {
      this.userRoles = this.getRolesFromMasterToken(decodedToken);
      getCompany = of(undefined);
    } else {
      this.userRoles = this.getRolesFromOperationalToken(decodedToken);
      getCompany = this.companyService.getCompanyInfoByVatNumber(decodedToken.aud);
    }

    // Carico le informazioni sull'utente
    const getUsername = this.userService.getUserByUsername(decodedToken.preferred_username);

    return zip(getUsername, getCompany).pipe(
      map(values => {
        const [username, company] = values;
        this.makeFrontendRoles(company);
        this.user.next(username);
        if (showLoginMessage) {
          this.notify({
            message: 'login.success',
            type: 'success',
          });
        }
        return true;
      }),
      catchError(err => {
        if (showLoginMessage) {
          this.notify({
            message: 'commons.error',
            type: 'error',
          });
        }
        this.frontendRole$.next(undefined);
        throw new Error(`${err}`);
      })
    );
  }

  private initRefreshToken(): void {
    this.eventBusService.refreshToken$
      .pipe(
        distinctUntilChanged(),
        filter(r => r === true),
        switchMap(() => this.refreshToken()),
        tap(newToken => {
          this.eventBusService.refreshToken$.next(false);
          this.eventBusService.emit(new EventData(EventDataEnum.TokenRefreshed, newToken));
        })
      )
      .subscribe();
  }

  private refreshToken(): Observable<AuthToken | null> {
    const isMaster = this.isAdmin === true;
    const token = this.userStorageService.operationalToken;
    return this.userService.refreshToken(token?.refresh_token || '').pipe(
      switchMap(res => {
        const responseToken = res;
        // Il refresh aggiorna il token principale
        this.userStorageService.masterToken = responseToken;

        // Se sono su master, allora lo associo anche all'operational token
        if (isMaster) {
          this.userStorageService.operationalToken = responseToken;
          return of(res);
        }

        // Altrimenti devo richiamare il setClient per aggiornare anche quel token
        return this.userService.setClient(this.currentClient || '');
      }),
      map(res => {
        const responseToken = res;
        this.userStorageService.operationalToken = responseToken;
        const helper = new JwtHelperService();
        this.decodedToken = helper.decodeToken(this.userStorageService.operationalToken.access_token);

        return responseToken;
      })
    );
  }

  private makeFrontendRoles(companyInfo?: CompanyInfo): void {
    if (companyInfo == null) {
      this.frontendRole$.next(FrontendUserRolesEnum.SUPERADMIN);
      return;
    }

    if (this.userRoles == null) {
      return;
    }

    // Se è un OP
    if (companyInfo.companyType?.code === CompanyTypeCode.PRODUCER_ORGANIZATION) {
      for (const role of this.userRoles) {
        if (role === UserRolesEnum.CLIENT_ADMIN) {
          this.frontendRole$.next(FrontendUserRolesEnum.OP_ADMIN);
          break;
        } else if (role === UserRolesEnum.VALIDATORE) {
          this.frontendRole$.next(FrontendUserRolesEnum.OP_VALIDATOR);
          break;
        } else if (role === UserRolesEnum.ADDETTO_INSERIMENTO) {
          this.frontendRole$.next(FrontendUserRolesEnum.OP_EMPLOYEE);
          break;
        }
      }
    }
    // Azienda senza OP
    else if (companyInfo.companyType?.code === CompanyTypeCode.FARM && companyInfo.masterCompany == null) {
      for (const role of this.userRoles) {
        if (role === UserRolesEnum.CLIENT_ADMIN) {
          this.frontendRole$.next(FrontendUserRolesEnum.AZ_ADMIN);
          break;
        } else if (role === UserRolesEnum.VALIDATORE) {
          this.frontendRole$.next(FrontendUserRolesEnum.AZ_VALIDATOR);
          break;
        } else if (role === UserRolesEnum.ADDETTO_INSERIMENTO) {
          this.frontendRole$.next(FrontendUserRolesEnum.AZ_EMPLOYEE);
          break;
        }
      }
    }
    // Azienda con OP
    else {
      for (const role of this.userRoles) {
        if (role === UserRolesEnum.CLIENT_ADMIN) {
          this.frontendRole$.next(FrontendUserRolesEnum.AZ_WITH_OP_ADMIN);
          break;
        } else if (role === UserRolesEnum.VALIDATORE) {
          this.frontendRole$.next(FrontendUserRolesEnum.AZ_WITH_OP_VALIDATOR);
          break;
        } else if (role === UserRolesEnum.ADDETTO_INSERIMENTO) {
          this.frontendRole$.next(FrontendUserRolesEnum.AZ_WITH_OP_EMPLOYEE);
          break;
        }
      }
    }
  }

  private getClientsAndShowModal(
    username: string,
    firstName: string,
    isDismissable = false
  ): Observable<AuthToken | null> {
    if (!username) {
      throw new Error('No Username');
    }
    return this.userService.getUserClients(username).pipe(
      switchMap(clients => {
        // Scelgo il client e genero il token per il client
        this.userStorageService.userClients = this.sortClients(clients);
        return this.showModalSelectCompany(firstName, isDismissable);
      })
    );
  }

  /**
   * Visualizza la modale di selezione Client
   *
   * @returns
   */
  private showModalSelectCompany(firstName: string, isDismissable = false): Observable<AuthToken | null> {
    this.loaderService.hideLoader();
    if (this.userStorageService.userClients == null) {
      return new Observable(obs => {
        obs.next();
        obs.complete();
      });
    }

    // Se l'utente ha un solo ruolo
    if (this.userStorageService.userClients.length === 1) {
      // Se è l'ADMIN di sistema
      if (this.userStorageService.userClients[0].clientId === UserService.MASTER_CLIENT_ID) {
        return of(this.userStorageService.masterToken);
      }
      // altrimenti effettuo direttamente la chiamata di selezione azienda
      return this.userService.setClient(this.userStorageService.userClients[0].clientId);
    }

    // Altrimenti visualizzo il popup di scelta

    // Apro la modale
    return from(
      this.modalCtrl.create({
        component: CambioContestoModalComponent,
        componentProps: {
          clients: this.userStorageService.userClients,
          clientSelezionato: this.currentClient,
          isDismissable,
          firstName,
        },
        backdropDismiss: false,
      })
    ).pipe(
      // Attendo l'evento di chiusura modale
      switchMap(cambioContestoModal => {
        cambioContestoModal.present().finally(() => {});
        return from(cambioContestoModal.onDidDismiss());
      }),
      switchMap(data => {
        const clientId = data.data;
        this.loaderService.showLoader();
        if (isDismissable && clientId == null) {
          return of(null);
        }
        // Se sono un ADMIN, utilizzo il masterToken come Operational Token
        if (clientId === UserService.MASTER_CLIENT_ID) {
          return of(this.userStorageService.masterToken);
        }
        return this.userService.setClient(clientId as string);
      })
    );
  }

  /**
   * Recupera i ruoli dell'utente, dal JWT Token Master
   *
   * @param decodedToken
   * @returns
   */
  private getRolesFromMasterToken(decodedToken: JwtToken): UserRolesEnum[] {
    const userRoles: UserRolesEnum[] = [];
    // Ricavo i ruoli dell'utente
    if (decodedToken && decodedToken.resource_access) {
      const tnt = (decodedToken.resource_access as any)[UserService.MASTER_CLIENT_ID];
      if (tnt && tnt.roles && tnt.roles.length > 0) {
        tnt.roles.forEach((role: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (userRoleValues.includes(role)) {
            const userRole = role as UserRolesEnum;
            userRoles.push(userRole);
          }
        });
      }
    }

    return userRoles;
  }

  /**
   * Recupera i ruoli dell'utente, dal JWT Token Operational
   *
   * @param decodedToken
   * @returns
   */
  private getRolesFromOperationalToken(decodedToken: JwtToken): UserRolesEnum[] {
    const userRoles: UserRolesEnum[] = [];
    // Ricavo i ruoli dell'utente
    if (decodedToken && decodedToken.resource_access && decodedToken.aud) {
      const tnt = (decodedToken.resource_access as any)[decodedToken.aud];
      if (tnt && tnt.roles && tnt.roles.length > 0) {
        tnt.roles.forEach((role: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (userRoleValues.includes(role)) {
            const userRole = role as UserRolesEnum;
            userRoles.push(userRole);
          }
        });
      }
    }

    return userRoles;
  }

  /**
   * Ordina alfabeticamente i Client, mettendo in cima alla lista il Client Master
   *
   * @param clients
   * @returns
   */
  private sortClients(clients: ClientInfo[]): ClientInfo[] {
    const result: ClientInfo[] = [];
    const findMasterIndex = clients.findIndex(c => c.clientId === UserService.MASTER_CLIENT_ID);
    if (findMasterIndex >= 0) {
      // Nell'app non utilizzo il client master;
      // result.push(clients[findMasterIndex]);
      clients.splice(findMasterIndex, 1);
    }
    clients.sort((a, b) => (a.name > b.name ? 1 : -1));
    result.push(...clients);
    return result;
  }

  private notify(notificationData: NotificationMessage): void {
    this.eventBusService.emit(new EventData(EventDataEnum.Notification, notificationData));
  }

  private updatePassword(username: string): Observable<boolean> {
    this.loaderService.hideLoader();
    // Apro la modale
    return from(
      this.modalCtrl.create({
        component: CambioPasswordModalComponent,
        backdropDismiss: false,
      })
    ).pipe(
      // Attendo l'evento di chiusura modale
      switchMap(cambioPwdModal => {
        cambioPwdModal.present().finally(() => {});
        return from(cambioPwdModal.onDidDismiss());
      }),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      switchMap(data => {
        const pwData = data.data as IChangePassword;
        if (pwData != null) {
          const password: string = pwData.oldPassword || '';
          const newPassword: string = pwData.newPassword || '';
          this.loaderService.showLoader();
          return this.userService.updatePasswordWithOldPassword(username, password, newPassword).pipe(
            map(() => {
              this.loaderService.hideLoader();
              this.notify({
                message: 'commons.resetPassword',
                type: 'success',
              });
              return true;
            }),
            catchError(err => {
              console.error(err);
              this.loaderService.hideLoader();
              if (err && err.error && err.error.detail) {
                this.notify({
                  message: `${err.error.detail}`,
                  type: 'error',
                });
              }
              throw new Error(`${err}`);
            })
          );
        }
        return of(false);
      })
    );
  }

  private postLogin(username: string, firstName: string): Observable<boolean> {
    return this.getClientsAndShowModal(username, firstName).pipe(
      switchMap(operationalToken => {
        if (operationalToken) {
          this.userStorageService.operationalToken = operationalToken;
          return this.initSession(true);
        }
        return of(false);
      }),
      catchError(error => {
        this.loaderService.hideLoader();
        this.notify({
          message: 'login.error',
          type: 'error',
        });
        throw new Error(`${error}`);
      }),
      tap(() => {
        this.loaderService.hideLoader();
        this.isLoggedIn.next(true);
        this.navCtrl.navigateRoot(['/authenticated'], { replaceUrl: true }).finally(() => {});
      })
    );
  }
}
