import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { HarvestLotsRoutingModule } from './harvest-lots-routing.module';
import { HarvestLotsComponent } from './pages/harvest-lots/harvest-lots.component';
import { AddEditHarvestLotsComponent } from './pages/add-edit-harvest-lots/add-edit-harvest-lots.component';
import { CardHarvestLotComponent } from './components/card-harvest-lot/card-harvest-lot.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomInputsModule } from '../shared/components/custom-inputs/custom-inputs.module';

@NgModule({
  declarations: [HarvestLotsComponent, AddEditHarvestLotsComponent, CardHarvestLotComponent],
  imports: [
    CommonModule,
    HarvestLotsRoutingModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CustomInputsModule,
  ],
  providers: [DatePipe],
})
export class HarvestLotsModule {}
