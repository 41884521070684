export class ToolbarConfig {
  showBackButton = false;
  showTitle = false;
  showTitleIcon = false;
  rightButtonIcon = '';
  leftButtonIcon = '';
  showHamburgerMenu = false;
  showCompanyRole = false;
  bgTransparent = false;

  constructor() {}

  public static mainTabConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = false;
    toolbar.showTitle = true;
    toolbar.showTitleIcon = true;
    toolbar.rightButtonIcon = '';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = false;
    toolbar.showHamburgerMenu = false;
    toolbar.showCompanyRole = false;
    return toolbar;
  }

  // OnlyBack
  public static navigationConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = true;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = '';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = false;
    toolbar.showHamburgerMenu = false;
    toolbar.showCompanyRole = true;
    return toolbar;
  }

  // OnlyBack Transparen
  public static navigationTransparentConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = true;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = '';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = true;
    toolbar.showHamburgerMenu = false;
    toolbar.showCompanyRole = true;
    return toolbar;
  }

  // Modal
  public static modalConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = false;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = 'fas fa-times';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = true;
    toolbar.showHamburgerMenu = false;
    return toolbar;
  }

  // Menu
  public static menuConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = false;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = '';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = true;
    toolbar.showHamburgerMenu = true;
    toolbar.showCompanyRole = true;
    return toolbar;
  }

  // BackAndActions
  public static navActionsConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = true;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = 'fas fa-ellipsis-v';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = false;
    toolbar.showHamburgerMenu = false;
    toolbar.showCompanyRole = true;
    return toolbar;
  }

  // BackAndTitle
  public static navTitleConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = true;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = '';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = false;
    toolbar.showHamburgerMenu = false;
    toolbar.showCompanyRole = true;
    return toolbar;
  }

  // BackAndConfirm
  public static navConfirmConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = true;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = 'fas fa-check';
    toolbar.leftButtonIcon = '';
    toolbar.bgTransparent = false;
    toolbar.showHamburgerMenu = false;
    toolbar.showCompanyRole = true;
    return toolbar;
  }

  // ModalAndConfirm
  public static modalConfirmConfig(): ToolbarConfig {
    const toolbar = new ToolbarConfig();
    toolbar.showBackButton = false;
    toolbar.showTitle = false;
    toolbar.showTitleIcon = false;
    toolbar.rightButtonIcon = 'fas fa-check';
    toolbar.leftButtonIcon = 'fas fa-times';
    toolbar.bgTransparent = true;
    toolbar.showHamburgerMenu = false;
    return toolbar;
  }
}
