import { Injectable } from '@angular/core';
import { EventBusService } from './event-bus.service';
import { EventData, EventDataEnum } from '../models/event.class';
import { NotificationMessage } from '../models/notification-message.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private eventBusService: EventBusService) {}

  info(message: string, messageParameters?: any, title?: string, duration?: number): void {
    const notificationData: NotificationMessage = {
      title,
      message,
      messageParameters,
      type: 'info',
      duration,
    };
    this.show(notificationData);
  }

  success(message: string, messageParameters?: any, title?: string, duration?: number): void {
    const notificationData: NotificationMessage = {
      title,
      message,
      messageParameters,
      type: 'success',
      duration,
    };
    this.show(notificationData);
  }

  warning(message: string, messageParameters?: any, title?: string, duration?: number): void {
    const notificationData: NotificationMessage = {
      title,
      message,
      messageParameters,
      type: 'warn',
      duration,
    };
    this.show(notificationData);
  }

  error(message: string, messageParameters?: any, title?: string, duration?: number): void {
    const notificationData: NotificationMessage = {
      title,
      message,
      messageParameters,
      type: 'error',
      duration,
    };
    this.show(notificationData);
  }

  private show(notificationData: NotificationMessage): void {
    this.eventBusService.emit(new EventData(EventDataEnum.Notification, notificationData));
  }
}
