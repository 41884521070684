<app-toolbar-header toolbarType="main"></app-toolbar-header>

<div class="title-header">
  <ion-label class="Title c-dark-1">
    {{ 'commons.changePassword' | translate }}
  </ion-label>
  <ion-label class="Section-Title c-gray-1">
    {{ 'commons.passwordInfo' | translate }}
  </ion-label>
</div>
<ion-content>
  <form [formGroup]="changePasswordForm">
    <ion-list lines="none">
      <input-password
        formControlName="oldPassword"
        [label]="'change_password.old_password' | translate"
        [placeholder]="'change_password.old_password' | translate"></input-password>

      <input-password
        formControlName="newPassword"
        [label]="'change_password.new_password' | translate"
        [placeholder]="'change_password.pick_password' | translate"></input-password>

      <input-password
        formControlName="confirmPassword"
        [label]="'change_password.confirm_password' | translate"
        [placeholder]="'change_password.pick_password' | translate"></input-password>
    </ion-list>

    <ion-button expand="block" [disabled]="!changePasswordForm.valid" (click)="changePassword()">
      {{ 'commons.confirm' | translate }}
    </ion-button>
  </form>
</ion-content>
