import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AlertController, IonicSafeString, ToastController } from '@ionic/angular';

import { SwUpdate } from '@angular/service-worker';
import { Subscription, from, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventDataEnum } from './core/models/event.class';
import { NotificationMessage } from './core/models/notification-message.interface';
import { EventBusService } from './core/services/event-bus.service';
import { LanguageService } from './core/services/language.service';
import { LoaderService } from './core/services/loader.service';
import { SessionService } from './core/services/sessions.service';
import { CookiebotService } from './core/services/cookiebot.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
  private subs: Subscription = new Subscription();

  constructor(
    private loaderService: LoaderService,
    private eventBusService: EventBusService,
    private toastCtrl: ToastController,
    private sessionService: SessionService,
    private alertCtrl: AlertController,
    private languageService: LanguageService,
    private cookiebotService: CookiebotService,
    private renderer: Renderer2,
    private readonly swUpdate: SwUpdate
  ) {
    this.initPwa();
    this.cookiebotService.init(this.renderer);
    this.initLogoutEvent();
    this.initNotificationEvent();
  }

  ngOnInit(): void {
    console.log(`App Version ${environment.version.version}: ${environment.version.commitHash}`);
  }

  ngAfterViewInit(): void {
    const loadingIndicator = document.getElementById('fullpage-loader');
    if (loadingIndicator) {
      this.loaderService.setSpinnerContainer(loadingIndicator);
      this.loaderService.hideLoader();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initLogoutEvent(): void {
    const logoutEvt = this.eventBusService.on(EventDataEnum.Logout, () => {
      this.sessionService.logout();
    });
    this.subs.add(logoutEvt);
  }

  private initNotificationEvent(): void {
    const notificationEvt = this.eventBusService
      .onObservable<NotificationMessage>(EventDataEnum.Notification)
      .pipe(
        switchMap(value => {
          const { message, messageParameters, type, title, duration } = value;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const mex: string = this.languageService.translate.instant(message, messageParameters) || '';
          const tit: string | undefined = title ? this.languageService.translate.instant(title) : undefined;
          return from(this.showMessage(mex, type, tit, duration));
        })
      )
      .subscribe(() => {});
    this.subs.add(notificationEvt);
  }

  private async showMessage(
    content: string,
    severity: 'success' | 'warn' | 'info' | 'error',
    header?: string,
    duration: number = 3000
  ): Promise<void> {
    let color = 'light';
    let iconSrc = '';

    switch (severity) {
      case 'success':
        color = 'success';
        iconSrc = '/assets/icon/check-circle-solid.svg';
        break;
      case 'warn':
        color = 'warning';
        iconSrc = '/assets/icon/exclamation-triangle-solid.svg';
        break;
      case 'info':
        color = 'secondary';
        iconSrc = '/assets/icon/info-circle-solid.svg';
        break;
      case 'error':
        color = 'danger';
        iconSrc = '/assets/icon/times-circle-solid.svg';
        break;
    }

    let message: IonicSafeString | string = content;
    if (!!iconSrc) {
      message =
        new IonicSafeString(`<style>div{display:flex;align-items:flex-start;justify-content:center}.icon-container{margin-right:12px;}ion-icon{width:20px;height:20px}</style>
            <div><div class="icon-container"><ion-icon src="${iconSrc}"></ion-icon></div><ion-label>${content}</ion-label></div>`);
    }

    const toast = await this.toastCtrl.create({
      duration,
      header,
      message,
      color,
      position: 'top',
      cssClass: `toast-${color}`,
    });

    await toast.present();
  }

  // PWA
  private initPwa(): void {
    if (this.swUpdate.versionUpdates) {
      const sub = this.swUpdate.versionUpdates.subscribe(evt => this.doSwUpdate(evt));
      this.subs.add(sub);
    }
  }

  private doSwUpdate(evt: any): void {
    console.log('UpdateService: versionUpdates', evt);
    switch (evt.type) {
      case 'VERSION_DETECTED':
        console.log(`Downloading new app version: ${evt.version.hash}`);
        break;
      case 'VERSION_READY':
        console.log(`Current app version: ${evt.currentVersion.hash}`);
        console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
        this.presentUpdateAlert().finally(() => {});
        break;
      case 'VERSION_INSTALLATION_FAILED':
        console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        break;
    }
  }

  private async confirmUpdate(): Promise<void> {
    await this.swUpdate.activateUpdate();
    location.reload();
  }

  private async presentUpdateAlert(): Promise<void> {
    const header = this.languageService.translate.instant('updates.nuovo_aggiornamento');
    const message = this.languageService.translate.instant('updates.nuovo_aggiornamento_message');
    const alert = await this.alertCtrl.create({
      header,
      message,
      mode: 'md',
      cssClass: 'top-alert',
      buttons: [
        {
          text: this.languageService.translate.instant('commons.no'),
          role: 'cancel',
          handler: (): void => {},
        },
        {
          text: this.languageService.translate.instant('commons.yes'),
          role: 'confirm',
          handler: (): void => {
            this.confirmUpdate().finally(() => {});
          },
        },
      ],
    });

    await alert.present();
  }
}
