import { inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivateFn } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export const canDeactivateForm: CanDeactivateFn<CheckDeactivateForm> = async (component: any) => {
  const alertController = inject(AlertController);
  const translate = inject(TranslateService);

  if (component.unsavedForm) {
    const commons = translate.instant('commons');
    const alert = await alertController.create({
      header: commons.warning,
      message: commons.form_change_alert,
      buttons: [
        {
          text: commons.no,
          role: 'cancel',
          handler: () => false,
        },
        {
          text: commons.yes,
          role: 'confirm',
          handler: () => true,
        },
      ],
    });
    await alert.present();
    const result = await alert.onDidDismiss();

    return result.role === 'confirm';
  } else {
    return true;
  }
};

export class CheckDeactivateForm {
  public unsavedForm = false;
  protected subs = new Subscription();
  constructor(protected navCtrl: NavController) {}

  /**
   * Consente la navigazione indietro senza l'alert di blocco del form
   */
  protected navigateBack(): void {
    this.unsavedForm = false;
    this.navCtrl.back();
  }

  /**
   * Inizializza il controllo del cambio sul form
   */
  protected initCheckChangedForm(operationForm: FormGroup): void {
    const subChange = operationForm.statusChanges.subscribe(() => {
      this.unsavedForm = operationForm.dirty === true;
    });

    this.subs.add(subChange);
  }
}
