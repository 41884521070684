export class ImagesHelper {
  public static getBase64ImageFromBase64Content(base64Content?: string): string | undefined {
    if (!base64Content || typeof base64Content !== 'string') {
      return undefined;
    }

    let mimeType = '';
    try {
      // Converte la stringa Base64 in un array di byte
      const byteCharacters = atob(base64Content);

      // Estrae i primi 4 byte dall'array di byte
      const byteValues = new Array(4);
      for (let i = 0; i < 4; i++) {
        byteValues[i] = byteCharacters.charCodeAt(i);
      }

      // Converte i 4 byte in una stringa usando il codice ASCII
      const hexValues = byteValues.map(value => {
        const hex = value.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      });

      const hexString = hexValues.join('');

      // Verifica il tipo di file in base ai primi byte dell'array
      switch (hexString) {
        case '89504e47':
          mimeType = 'image/png';
          break;
        case '47494638':
          mimeType = 'image/gif';
          break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          mimeType = 'image/jpeg';
          break;
        default:
          mimeType = '';
          break;
      }
    } catch (error) {}

    const base64Image = `data:${mimeType};base64,${base64Content}`;

    return base64Image;
  }
}
