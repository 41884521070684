/* eslint-disable id-blacklist */
import { GeoGeometry } from './geoportale.interface';

export enum CompanyTypeCode {
  PRODUCER_ORGANIZATION = 'OP',
  FARM = 'AA',
}

export interface CompanyType {
  id: number;
  companyType: string;
  companyTypeEn: string;
  code: CompanyTypeCode;
}

export interface CompanyInfo {
  id?: number;
  companyType?: CompanyType;
  companyName: string;
  region: string;
  province: string;
  city: string;
  postalCode: string;
  address: string;
  vatNumber: string;
  mail?: string;
  certifiedMail?: string;
  phoneNumber?: string;
  creationDate?: string;
  endDate?: string | null;
  facebook?: string;
  instagram?: string;
  whatsapp?: string;
  logo?: string;
  localizedContents?: LocalizedContent[];
  managersUsername?: string[];
  blockChainType?: BlockchainType;
  masterCompany?: CompanyInfo;
}

export interface CompanyInfoDto {
  id: number;
  address: string;
  city: string;
  companyName: string;
  vatNumber: string;
}

export interface LocalizedContent {
  id?: number | undefined;
  title?: string; // TO REMOVE
  description: string;
  language: Language;
}

export interface Language {
  id_language: number;
  codISO: string;
  description: string;
  ico: string;
}

export interface IrrigationType {
  id: number;
  irrigationType: string;
  irrigationTypeEn: string;
}

export interface ParcelInfo {
  id?: number;
  company?: CompanyInfo;
  parcelName: string;
  region: string;
  province: string;
  city: string;
  postalCode: string;
  address: string;
  cropArea: number;
  irrigationType?: IrrigationType;
  creationDate?: string;
  endDate?: string;
  parcelShape?: GeoGeometry;
  enabled: boolean;
  notes?: string | null;
  cadastralsInfo?: CadastralInfo[];
}

export interface ParcelInfoFilter {
  parcelName?: string;
  enabled?: boolean;
}

export interface CadastralInfo {
  id?: number;
  region?: string;
  province?: string;
  city?: string;
  cadastralCityCode?: string;
  cadastralSheet?: number;
  cadastralParcel?: number;
  totalArea?: number;
  usedArea?: number;
}

export interface ProcessingState {
  id: number;
  state: string;
  stateEn: string;
  code: ProcessingStateCodeEnum;
}

export enum ProcessingStateCodeEnum {
  INSERITO = 'I',
  DA_VALIDARE = 'D',
  VALIDATO = 'V',
  DA_CHIUDERE = 'H',
  CHIUSO = 'C',
  PENDING = 'P',
}

export interface CropType {
  id?: number;
  code: string;
  name: string;
  nameEn: string;
  botanicalName: string;
}

export interface CropCycle {
  id?: number;
  endingDate?: string;
  startingDate: string;
  cropType?: CropType; // cropType is not mandatory
  product?: Product;
  cultivarType?: string;
  parcel: ParcelInfo;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
  blockchain?: BlockchainType;
  countDOperation?: number; // utilizzato solo a front-end pagina di lista dei cicli colturali
}

export interface CropCycleOverview {
  id?: number;
  endingDate?: string;
  startingDate: string;
  cropType?: CropType; // cropType is not mandatory
  cultivarType?: string;
  processingState?: ProcessingState;
  parcelInfo: ParcelInfo;
  image?: string;
  toValidate?: number;
}

export enum SortOrderEnum {
  ASC = 1,
  DESC = -1,
}

export interface CropFilter {
  stringSearch?: string;
  processingState?: ProcessingState;
  rangeDates?: Date[];
}

export interface ProductFilter {
  stringSearch?: string;
  status?: boolean;
  codISO?: string;
}

export interface CropSort {
  sortField: string;
  sortOrder: SortOrderEnum;
}

export interface PhytosanitaryTreatmentSort {
  sortField: string;
  sortOrder: SortOrderEnum;
}

export interface ParcelInfoStatus {
  state: string;
  number: number;
  names: string[];
  ids: number[];
}

export interface CropStatus {
  state: string;
  number: number;
  names: string[];
  ids: number[];
}

export interface DashboardStatus {
  parcelInfoStatus: ParcelInfoStatus[];
  cropCycleStatus: CropStatus[];
  cropOperationStatus: CropStatus[];
}

export interface DashStates {
  inserted: number;
  toValidate: number;
  toClose: number;
  closed: number;
}

export interface BlockchainType {
  id: number;
  name: string;
  description: string;
  descriptionEn: string;
  info: string;
  infoEn: string;
  image: any;
}

export interface CropOperation {
  id?: number;
  startingDate: string;
  endingDate?: string;
  amount?: number;
  measurementUnity?: MeasurementUnity;
  cropResource?: string;
  cropCycle: CropCycle;
  operationType: OperationType;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
  notes?: string;
}

export interface FertOperation {
  id?: number;
  cropCycle: CropCycle;
  startingDate: string;
  endingDate?: string;
  fertilizerName: string;
  fertType: FertType;
  amount: number;
  measurementUnity?: MeasurementUnity;
  amountWhater?: number;
  measurementWhaterUnity?: MeasurementUnity;
  isOrganic?: boolean;
  nitrogenContent?: number;
  phosphorContent?: number;
  potassiumContent?: number;
  otherElementsDescription?: string;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
  notes?: string;
}

export interface PhytosanitaryTreatment {
  id?: number;
  phytosanitaryProduct: string;
  productName: string;
  phytosanitaryAction: string;
  activeSubstance: string;
  phenologicalPhase: string;
  adversity: string;
  dose?: number;
  uomDose?: MeasurementUnity;
  preparationQuantity?: number;
  uomPreparation?: MeasurementUnity;
  treatmentDate: string;
  shortageDays: number;
  suggestedHarvestDate: string;
  organic?: boolean;
  cropCycle: CropCycle;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
  notes?: string;
}

export interface MeasurementUnity {
  id?: number;
  measurementUnity: string;
  measurementUnityEn: string;
}

export interface OperationType {
  id?: number;
  operationType: string;
  operationTypeEn: string;
  isQuantitative: boolean;
}

export interface FertType {
  id?: number;
  fertType: string;
  fertTypeEn: string;
}

export interface CropAnalysis {
  id?: number;
  cropCycle: CropCycle;
  harvestLot?: Harvest;
  resultingDate?: string;
  samplingDate: string;
  name: string;
  labName?: string;
  resultType: string;
  notes?: string;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
}

export interface Harvest {
  id?: number;
  companyFarmName?: string;
  cropCycle: CropCycle;
  harvestDate: string | Date;
  receivingDate?: string | Date;
  companyInfo: CompanyInfo;
  analysis?: CropAnalysis;
  notes?: string;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
}

export interface SaleCategory {
  id?: number;
  categoryType: string;
  categoryTypeEn: string;
}

export interface SaleLot {
  id?: number;
  companyInfo: CompanyInfo;
  packingDate?: string;
  salesDate?: string;
  categoryType: SaleCategory;
  amount?: number;
  measurementUnity?: MeasurementUnity;
  idExternalLot?: string; // TODO
  notes?: string;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
  harvestLot?: Harvest;
}

export interface PhytosanitaryProduct {
  code: string;
  productName: string;
  productState: string;
  phytosanitaryAction: string;
  activeSubstance: string;
  registrationNumber: string;
}

export interface AntagonistOrganisms {
  id?: number;
  cropCycle: CropCycle;
  applicationDate?: string;
  antagonistName: string;
  targetName?: string;
  amount?: number;
  measurementUnity?: MeasurementUnity;
  modificationDate?: Date;
  processingState?: ProcessingState;
  modificationUsername?: string;
}

export interface AnalysisFile {
  _internalId?: string;
  id?: number;
  name: string;
  file: string | ArrayBuffer | null;
  prefix: string;
  analysis?: CropAnalysis;
}

export interface HarvestReceivingDateDto {
  id: number;
  receivingDate: string;
}

export interface EnvironmentalDataStations {
  id?: number;
  resourceId?: string;
  resourceIdName?: string;
  notes?: string;
  parcelInfos?: ParcelInfo[];
  companyInfo?: CompanyInfo;
  placeType?: PlaceType;
  modificationDate?: Date;
  modificationUsername?: string;
}

export interface ResourcesResponse {
  resources?: Resources[];
  cloudRegion?: string;
  apiVersion?: string;
  kRevision?: string;
  millis?: number;
}

export interface ResourceResponse {
  resource?: Resources;
  cloudRegion?: string;
  apiVersion?: string;
  kRevision?: string;
  millis?: number;
}

export interface Resources {
  name?: string;
  createMillis?: number;
  resourceId?: string;
  position?: any;
  lastDataMillis?: number;
  topics?: string[];
}

export interface Gallery {
  id?: number;
  companyInfo: PartialCompanyInfo;
  image: string;
  prefix: string;
  position: number;
}

export interface PartialCompanyInfo {
  id: number;
  companyName?: string;
  vatNumber?: string;
}

export interface ReorderGallery {
  id: number;
  position: number;
}

export interface ProductSectionTitle {
  id: number;
  position: number;
  enabled: true;
  titles: ProductSectionTitleLanguage[];
}

export interface ProductSectionTitleLanguage {
  id: number;
  language: Language;
  title: string;
}

export interface LocalizedContentProduct {
  id: number;
  section: ProductSectionTitle;
  description: string;
  language: Language;
}

export interface Product {
  id: number;
  company: CompanyInfo;
  cropType: CropType;
  cultivarType: string;
  image: string;
  enabled: boolean;
  modificationDate: string;
  modificationUsername: string;
  underCultivation: boolean;
  deletable: boolean;
  localizedContents: LocalizedContentProduct[];
}

export interface ProductDto {
  id: number;
  cropType: CropType;
  cultivarType: string;
  image: string;
  enabled: boolean;
  deletable: boolean;
  underCultivation: boolean;
  titleStatus: { title: string; status: string }[];
}

export interface PlaceType {
  id: number;
  name: string;
  nameEn: string;
}

export interface ResourcesAverage {
  placeType: PlaceType;
  resourceId: string;
  environmentalStationId: number;
  resourceIdName: string;
  lastSurvey: number;
  iotTopics: ResourceAverage[];
}

export interface ResourceAverage {
  topicName: string;
  uom: string;
  lastValue: number;
  minValue: number;
  maxValue: number;
  averageValue: number;
  averagePercentuage: number;
}
