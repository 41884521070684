import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HarvestLotsComponent } from './pages/harvest-lots/harvest-lots.component';
import { AddEditHarvestLotsComponent } from './pages/add-edit-harvest-lots/add-edit-harvest-lots.component';
import { canDeactivateForm } from '../core/guards/deactivate-form';

const routes: Routes = [
  { path: '', component: HarvestLotsComponent },
  { path: 'new', component: AddEditHarvestLotsComponent, canDeactivate: [canDeactivateForm] },
  { path: ':idOperation', component: AddEditHarvestLotsComponent, canDeactivate: [canDeactivateForm] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HarvestLotsRoutingModule {}
