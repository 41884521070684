import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CambioPasswordModalComponent } from './cambio-password-modal.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolbarHeaderModule } from '../toolbar-header/toolbar-header.module';
import { CustomInputsModule } from '../custom-inputs/custom-inputs.module';

@NgModule({
  declarations: [CambioPasswordModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ToolbarHeaderModule,
    CustomInputsModule,
  ],
  exports: [CambioPasswordModalComponent],
})
export class CambioPasswordModalModule {}
