import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ClientInfo } from 'src/app/core/models/user.interface';

@Component({
  selector: 'app-card-contesto',
  templateUrl: './card-contesto.component.html',
  styleUrls: ['./card-contesto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardContestoComponent {
  @Input() clientInfo?: ClientInfo;
  @Output() onClick: EventEmitter<ClientInfo> = new EventEmitter();

  constructor() {}
}
