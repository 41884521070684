/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Injector,
  INJECTOR,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilityHelper } from 'src/app/shared/helpers/utility.helper';

@Component({
  selector: 'input-password',
  templateUrl: 'input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputPasswordComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {
  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public value: string;

  @Input()
  public disabled: boolean;

  public isHide = true;

  sub = new Subscription();

  formControl: FormControl;
  error$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(@Inject(INJECTOR) private injector: Injector, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const formControl = this.injector.get(NgControl).control as FormControl;
    if (formControl) {
      this.formControl = formControl;
    } else {
      this.formControl = new FormControl('');
    }

    const error1 = UtilityHelper.checkFormControlError(this.formControl);
    this.error$.next(error1);
    this.formControl.markAsTouched();

    const sub = this.formControl.valueChanges.subscribe(() => {
      const error = UtilityHelper.checkFormControlError(this.formControl);
      this.error$.next(error);
    });
    this.sub.add(sub);
    this.cd.detectChanges();
  }

  public showHidePassword(): void {
    this.isHide = !this.isHide;
  }

  public changeValue(args: CustomEvent): void {
    this.writeValue(args.detail.value);
    this.onTouch();
  }

  // Allow Angular to set the value on the component
  public writeValue(value: string): void {
    this.onChange(value);
    this.value = value;
  }

  // Save a reference to the change function passed to us by
  // the Angular form control
  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  // Save a reference to the touched function passed to us by
  // the Angular form control
  public registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }

  // Allow the Angular form control to disable this input
  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: Function = (value: string) => {};
  onTouch: Function = () => {};

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
