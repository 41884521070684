<ion-header id="header">
  <ion-toolbar [class.bg-transparent]="hasBgTransparent">
    <!-- LEFT BUTTONS -->
    <ion-buttons slot="start">
      <!-- LEFT BUTTON -->
      <ion-button
        class="left-button"
        *ngIf="config.leftButtonIcon"
        (click)="toolbarClick.emit({ type: 'left', event: $event })">
        <span [class]="config.leftButtonIcon"></span>
      </ion-button>
      <!-- HAMBURGER MENU -->
      <ion-menu-button *ngIf="config.showHamburgerMenu"></ion-menu-button>
      <!-- BACK BUTTON -->
      <ion-back-button *ngIf="config.showBackButton" [defaultHref]="defaultHref"></ion-back-button>
    </ion-buttons>

    <!-- TITLE LOGO FULL -->
    <ion-title *ngIf="config.showTitle">
      <div class="title-container">
        <ion-icon src="/assets/logos/logo-esteso.svg"></ion-icon>
      </div>
    </ion-title>

    <!-- TITLE AZIENDA e RUOLO -->
    <ion-title *ngIf="config.showCompanyRole">
      <div class="title-company-role" *ngIf="companyRole$ | async as companyRole">
        <div class="Title c-dark-1">{{ companyRole.companyName }}</div>
        <div *ngIf="companyRole.role" class="H5 c-gray-1">
          {{ 'frontend_roles.' + companyRole.role | translate }}
        </div>
      </div>
    </ion-title>

    <!-- RIGHT BUTTONS -->
    <ion-buttons slot="end">
      <!-- CLOSE BUTTON | ACTIONS BUTTON | CONFIRM BUTTON-->
      <ion-button
        class="right-button"
        *ngIf="config.rightButtonIcon"
        (click)="toolbarClick.emit({ type: 'right', event: $event })"
        [disabled]="disableRightButton">
        <span [class]="config.rightButtonIcon"></span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
