/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookiebotService {
  public sdkLoaded = false;
  private renderer?: Renderer2;
  // Per testare, utilizzare il dominio cokbot.cvls.gm associato all'indirizzo 127.0.0.1
  private enableCookiebot = environment.enableCookieBot === true;
  private cbid = '7cc012cb-2d4f-4585-9a2b-1e0170a2105a'; // TEST: '407715b2-a635-44da-a2d0-423269a6dd8d';
  private blockingmode = 'auto'; // TEST: 'manual';

  constructor() {}

  getCookieDeclarationScript(renderer: Renderer2): HTMLScriptElement | undefined {
    if (!this.enableCookiebot) {
      return undefined;
    }

    const script = renderer.createElement('script') as HTMLScriptElement;
    script.src = `https://consent.cookiebot.com/${this.cbid}/cd.js`;
    script.id = 'CookieDeclaration';
    script.type = 'text/javascript';
    script.async = true;

    return script;
  }

  public init(renderer: Renderer2): void {
    this.renderer = renderer;
    this.loadSDK();
  }

  /**
   * CARICAMENTO SDK
   */

  private loadSDK(): void {
    if (this.enableCookiebot) {
      console.log('Loading Cookiebot');
      if (!this.sdkLoaded) {
        this.injectSDK();
      }
    }
  }

  private injectSDK(): any {
    if (this.renderer == null) {
      return;
    }

    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.src = 'https://consent.cookiebot.com/uc.js';
    script.id = 'Cookiebot';
    script.type = 'text/javascript';

    this.renderer.setAttribute(script, 'data-cbid', this.cbid);
    this.renderer.setAttribute(script, 'data-blockingmode', this.blockingmode);

    this.renderer.appendChild(document.body, script);

    this.sdkLoaded = true;
  }
}
