import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CropCycle } from 'src/app/core/models/supply-chain.interface';
import { CropCycleCounter } from '../models/crop-cycle-infos.model';

@Injectable()
export class LazyCropCycleService {
  public cropCycle$ = new BehaviorSubject<CropCycle | undefined>(undefined);
  public cropCycleCounters$ = new BehaviorSubject<CropCycleCounter | undefined>(undefined);
  constructor() {}
}
