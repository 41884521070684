<ion-item
  *ngIf="textFormControl"
  lines="none"
  class="input-datetime"
  [class.item-interactive-disabled]="textFormControl.disabled">
  <ion-input
    [formControl]="textFormControl"
    type="text"
    aria-label=""
    [label]="label"
    labelPlacement="floating"
    [readonly]="true"
    [errorText]="(error$ | async | translate) || ' '"
    helperText=""
    (ionFocus)="inputFocus(modalDatetime)"
    validate>
  </ion-input>

  <ion-modal id="datetime-modal" #modalDatetime>
    <ng-template>
      <ion-datetime
        [value]="getCurrentISO()"
        [cancelText]="cancelText | translate"
        [clearText]="clearText | translate"
        [color]="color"
        [dayValues]="dayValues"
        [doneText]="doneText | translate"
        [firstDayOfWeek]="firstDayOfWeek"
        [hourCycle]="hourCycle"
        [hourValues]="hourValues"
        [locale]="locale"
        [max]="max"
        [min]="min"
        [minuteValues]="minuteValues"
        [mode]="mode"
        [monthValues]="monthValues"
        [multiple]="false"
        [preferWheel]="preferWheel"
        [presentation]="presentation"
        [readonly]="readonly"
        [showClearButton]="showClearButton"
        [showDefaultButtons]="showDefaultButtons"
        [showDefaultTimeLabel]="showDefaultTimeLabel"
        [showDefaultTitle]="showDefaultTitle"
        [size]="size"
        [yearValues]="yearValues"
        (ionChange)="setDate($event)"></ion-datetime>
    </ng-template>
  </ion-modal>
</ion-item>
