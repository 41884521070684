/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthToken } from '../models/auth-token.interface';
import { PageableOptions } from '../models/others-interfaces';
import { ClientInfo, User, UserFilter } from '../models/user.interface';
import { ImagesHelper } from 'src/app/shared/helpers/images.helper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public static MASTER_CLIENT_ID = 'lr4-master';
  public static MASTER_CLIENT_NAME = 'ADMIN';
  constructor(private http: HttpClient) {}

  /**
   * Effettua la login
   */
  login(username: string, password: string): Observable<AuthToken> {
    return this.http
      .post(`${environment.gravitee}/loginLR4/jwt`, {
        username,
        password,
      })
      .pipe(map(res => res as AuthToken));
  }

  /**
   * Aggiorna la password dell'utente (loggato)
   *
   * @param username
   * @param password
   * @param newPassword
   * @returns
   */
  updatePasswordWithOldPassword(username: string, password: string, newPassword: string): Observable<any> {
    return this.http.post(`${environment.gravitee}/loginLR4/users/password-modify`, {
      username,
      password,
      newPassword,
    });
  }

  /**
   * Invia la mail per il reset password
   *
   * @returns
   */
  sendResetPassword(email: string): Observable<any> {
    return this.http.post(`${environment.gravitee}/loginLR4/reset-password-request`, {
      email,
      username: email,
    });
  }

  /**
   * Aggiorna la password dell'utente (utilizzata per il secondo step del recupero password)
   *
   * @param email
   * @param token
   * @param password
   * @returns
   */
  updatePasswordWithToken(email: string, token: string, password: string): Observable<any> {
    return this.http.post(`${environment.gravitee}/loginLR4/password-update`, {
      email,
      token,
      password,
    });
  }

  /**
   * Aggiorna la password dell'utente e lo abilita (utilizzata per la creazione account)
   *
   * @param email
   * @param token
   * @param password
   * @returns
   */
  confirmAccountWithToken(email: string, token: string, password: string): Observable<any> {
    return this.http.post(`${environment.gravitee}/loginLR4/confirm-account`, {
      email,
      token,
      password,
    });
  }

  /**
   * Recupera i client su cui è abilitato l'utente
   *
   * @param username
   * @returns
   */
  /* getUserClients(username: string): Observable<ClientInfo[]> {
    return this.http.get(`${environment.gravitee}/loginLR4/clients-id-name/${username}`).pipe(
      map(res => {
        const clients: ClientInfo[] = [];
        if (res != null) {
          const keyVals = Object.entries(res);
          if (keyVals != null && keyVals.length > 0) {
            keyVals.forEach(kv => {
              const name = kv[0] === UserService.MASTER_CLIENT_ID ? UserService.MASTER_CLIENT_NAME : kv[1];
              clients.push({
                clientId: kv[0],
                name,
              });
            });
          }
        }
        return clients;
      })
    );
  }*/
  getUserClients(username: string): Observable<ClientInfo[]> {
    return this.http
      .get<{ id: number; companyName: string; vatNumber: string; logo: any; role?: string }[]>(
        `${environment.gravitee}/api-supply-chain/company-info/userstenants/${username}`
      )
      .pipe(
        map(res => {
          let clients: ClientInfo[] = [];
          if (res != null) {
            clients = res.map(r => {
              return {
                clientId: `${r.vatNumber}`,
                name: r.companyName,
                logo: r.logo ? ImagesHelper.getBase64ImageFromBase64Content(r.logo as string) : undefined,
                role: r.role,
              };
            });
          }
          return clients;
        })
      );
  }

  /**
   * Recupera una lista di utenti
   *
   * @returns
   */
  getAllUsers(): Observable<User[]> {
    return this.http.get(`${environment.gravitee}/loginLR4/users`).pipe(
      map(res => {
        const users = res as User[];
        if (users && users.length >= 1) {
          users.sort((a, b) => {
            return (a.lastName || '') > (b.lastName || '') ? 1 : -1;
          });
        }
        return users;
      })
    );
  }

  /**
   * Recupera una lista di utenti paginata
   *
   * @returns
   */
  getPaginatedUsers(filters?: UserFilter, options?: PageableOptions): Observable<User[]> {
    let httpParams = new HttpParams();

    if (filters) {
      if (filters.search) {
        httpParams = httpParams.append('search', filters.search);
      }

      if (filters.enabled != null) {
        httpParams = httpParams.append('q', `enabled:${filters.enabled}`);
      }
    }

    if (options != null && options.size != null && options.page != null) {
      const first = options.page * options.size;
      const max = first + options.size;
      httpParams = httpParams.append('max', max);
      httpParams = httpParams.append('first', first);
    }
    return this.http
      .get(`${environment.gravitee}/loginLR4/users`, {
        params: httpParams,
      })
      .pipe(
        map(res => {
          return res as User[];
        })
      );
  }

  /**
   * Recupera una lista di utenti
   *
   * @returns
   */
  getClientUsersByRole(
    clientId: string,
    roleName: string,
    filters?: UserFilter,
    options?: PageableOptions
  ): Observable<User[]> {
    let httpParams = new HttpParams();

    if (filters) {
      if (filters.search) {
        httpParams = httpParams.append('search', filters.search);
      }

      if (filters.enabled != null) {
        httpParams = httpParams.append('enabled', filters.enabled);
      }
    }

    if (options != null && options.size != null && options.page != null) {
      const first = options.page * options.size;
      const max = first + options.size;
      httpParams = httpParams.append('max', max);
      httpParams = httpParams.append('first', first);
    }

    return this.http
      .get(`${environment.gravitee}/loginLR4/users/clientRole/${clientId}/${roleName}`, {
        params: httpParams,
      })
      .pipe(
        map(res => {
          return res as User[];
        })
      );
  }

  /**
   * Crea un nuovo utente
   *
   * @param user
   * @returns
   */
  createUser(user: User): Observable<boolean> {
    return this.http
      .post(`${environment.gravitee}/loginLR4/users`, user, {
        responseType: 'text',
      })
      .pipe(
        map(res => {
          return !!res;
        })
      );
  }

  /**
   * Crea un nuovo employee per l'azienda, con ruolo specifico
   * Se non esiste lo crea, altrimenti aggiunge il ruolo all'utente trovato
   *
   * @param user
   * @returns
   */
  createEmployee(employee: User, clientId: string): Observable<boolean> {
    return this.http.post(`${environment.gravitee}/loginLR4/clients/${clientId}/user`, employee).pipe(
      map(res => {
        return !!res;
      })
    );
  }

  /**
   * Abilita/disabilita un utente
   *
   * @param user
   * @returns
   */
  enableDisableUser(user: User): Observable<boolean> {
    return this.http.post(`${environment.gravitee}/loginLR4/users/${user.username}/enable`, user).pipe(
      map(res => {
        return !!res;
      })
    );
  }

  /**
   * Cancella l'utente
   *
   * @param user
   * @returns
   */
  deleteUser(user: User): Observable<boolean> {
    return this.http.delete(`${environment.gravitee}/loginLR4/users/${user.username}`).pipe(
      map(res => {
        return !!res;
      })
    );
  }

  /**
   * Aggiorna l'utente
   *
   * @param user
   * @returns
   */
  updateUser(user: User): Observable<boolean> {
    return this.http.put(`${environment.gravitee}/loginLR4/users`, user).pipe(
      map(res => {
        return !!res;
      })
    );
  }

  /**
   * Recupera un utente tramite il suo username
   *
   * @param username
   * @returns
   */
  getUserByUsername(username?: string): Observable<User> {
    if (!username) {
      throw new Error('No username provided');
    }
    return this.http.get(`${environment.gravitee}/loginLR4/users/${username}`).pipe(
      map(res => {
        return res as User;
      })
    );
  }

  refreshToken(refreshToken: string): Observable<AuthToken | null> {
    const headers: HttpHeaders = new HttpHeaders().append('refresh-token', refreshToken);
    return this.http.post<AuthToken | null>(`${environment.gravitee}/loginLR4/refresh-token`, {}, { headers });
  }

  /**
   * Seleziona il client, recuperando il relativo JWT
   *
   * @param clientId
   * @returns
   */
  setClient(clientId: string): Observable<AuthToken | null> {
    return this.http
      .post(`${environment.gravitee}/loginLR4/setTenant/${clientId}`, null)
      .pipe(map(res => res as AuthToken));
  }
}
