import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedSupplychain',
  pure: false,
})
export class LocalizedSupplychainPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, field: string): string {
    if (!value) {
      return '';
    }
    const currentLanguage = this.translateService.currentLang || this.translateService.defaultLang;
    return currentLanguage === 'en' && value[`${field}En`] ? value[`${field}En`] : value[field];
  }
}
