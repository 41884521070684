import { Pipe, PipeTransform } from '@angular/core';
import { CompanyInfo } from 'src/app/core/models/supply-chain.interface';

@Pipe({
  name: 'companyAddress',
  pure: false,
})
export class CompanyAddressPipe implements PipeTransform {
  constructor() {}

  transform(company: CompanyInfo): string {
    if (company == null || !company.address || !company.city) {
      return '';
    }

    const postalCode = company.postalCode ? `${company.postalCode} ` : '';
    const prov = company.province ? ` ${company.province}` : '';

    return `${company.address}, ${postalCode}${company.city}${prov}`;
  }
}
