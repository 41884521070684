<ion-item *ngIf="formControl" lines="none" class="input-pwd">
  <ion-input
    [value]="value"
    [formControl]="formControl"
    [type]="isHide ? 'password' : 'text'"
    [placeholder]="placeholder"
    [label]="label"
    aria-label=""
    labelPlacement="floating"
    [errorText]="(error$ | async | translate) || ' '">
  </ion-input>
  <button type="button" class="input-clear-icon" (click)="showHidePassword()">
    <i class="fas" [class.fa-eye]="isHide" [class.fa-eye-slash]="!isHide"></i>
  </button>
</ion-item>
