import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CambioContestoModalComponent } from './cambio-contesto-modal.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolbarHeaderModule } from '../toolbar-header/toolbar-header.module';
import { CardContestoComponent } from './card-contesto/card-contesto.component';

@NgModule({
  declarations: [CambioContestoModalComponent, CardContestoComponent],
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule, ToolbarHeaderModule],
  exports: [CambioContestoModalComponent],
})
export class CambioContestoModalModule {}
