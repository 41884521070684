import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { map } from 'rxjs';
import { availableLanguages } from 'src/app/core/models/language.model';
import { SessionService } from 'src/app/core/services/sessions.service';
import { ToolbarConfig } from './toolbar-config.model';

@Component({
  selector: 'app-toolbar-header',
  templateUrl: './toolbar-header.component.html',
  styleUrls: ['./toolbar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarHeaderComponent implements OnChanges {
  @Input() toolbarType:
    | 'main'
    | 'navigation'
    | 'modal'
    | 'menu'
    | 'navActions'
    | 'navTitle'
    | 'navConfirm'
    | 'modalConfirm' = 'main';
  @Input() defaultHref = '/authenticated';
  @Input() disableRightButton = false;
  @Input() transparentBg?: boolean;
  @Output() toolbarClick: EventEmitter<{ type: 'left' | 'right'; event: any }> = new EventEmitter();

  public config: ToolbarConfig = new ToolbarConfig();
  public readonly availableLanguages = availableLanguages;

  public hasBgTransparent = false;

  public companyRole$ = this.sessionService.frontendRole$.asObservable().pipe(
    map(role => {
      if (role == null) {
        return {
          companyName: '',
          role: '',
        };
      }
      return {
        companyName: this.sessionService.currentClientName,
        role,
      };
    })
  );

  constructor(private sessionService: SessionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const toolbarType = changes['toolbarType']?.currentValue;
    if (toolbarType) {
      this.loadType();
    }
  }

  private loadType(): void {
    switch (this.toolbarType) {
      case 'main': // OnlyLogo
        this.config = ToolbarConfig.mainTabConfig();
        break;
      case 'navigation': // OnlyBack
        this.config = ToolbarConfig.navigationConfig();
        break;
      case 'modal': // Modal
        this.config = ToolbarConfig.modalConfig();
        break;
      case 'menu': // Menu
        this.config = ToolbarConfig.menuConfig();
        break;
      case 'navActions': // BackAndActions
        this.config = ToolbarConfig.navActionsConfig();
        break;
      case 'navTitle': // BackAndTitle
        this.config = ToolbarConfig.navTitleConfig();
        break;
      case 'navConfirm': // BackAndConfirm
        this.config = ToolbarConfig.navConfirmConfig();
        break;
      case 'modalConfirm': // ModalAndConfirm
        this.config = ToolbarConfig.modalConfirmConfig();
        break;
    }

    this.hasBgTransparent =
      this.transparentBg == null ? this.config.bgTransparent === true : this.transparentBg === true;
  }
}
