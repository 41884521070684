import { Component, Input } from '@angular/core';
import { ProcessingState } from 'src/app/core/models/supply-chain.interface';

@Component({
  selector: 'app-tag-state',
  templateUrl: './tag-state.component.html',
  styleUrls: ['./tag-state.component.scss'],
})
export class TagStateComponent {
  @Input()
  public state?: ProcessingState;

  constructor() {}
}
