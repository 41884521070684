import version from '../../version.json';

export const environment = {
  production: true,
  version,
  envName: 'prod',
  domain: '',
  urlPrivacy: 'public/privacy-policy',
  codeTable_url: 'https://agriverso-apim.open-lab.cloud/api-codetables',
  batch_url: 'https://agriverso-apim.open-lab.cloud/batch-codetables/sources',
  gravitee: 'https://agriverso-apim.open-lab.cloud',
  client_blockchain: 'https://agriverso-apim.open-lab.cloud/api-client',
  pdf_url: 'https://agriverso-apim.open-lab.cloud/pdfs',
  jwtAllowedDomains: ['agriverso-apim.open-lab.cloud'],
  geoportale_url: 'https://wms.cartografia.agenziaentrate.gov.it/inspire/ajax/ajax.php',
  enableCookieBot: false,
};
